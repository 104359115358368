import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AntTab, Wrapper, BoxWrapper, Step } from './Secondary.style';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import IframeComp from '../../../Iframe/iframe';
import { Mixpanel } from '../../../../helpers/Mixpanel';
import pallete from '../../../../config/palette';
import Joyride from 'react-joyride';
import { formatText } from '../../List/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { learningResourcesHighlightActions } from '../../../../redux/reducers/learningResourcesHighlight';
import constants from '../../../../config/constants';

const Secondary = ({ tabs, tabIndicatorColor, selectedtabcolor, location }) => {
  const [tabValue, setValue] = React.useState(0);
  const { name, tab, id, value } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [status, setStatus] = React.useState('');
  const isHighlightingActive = useSelector(
    (state) => state.learningResources.isHighlighting
  );
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);

    navigate(`/dashboard/${name}/${id}/${value}/${location}/${newValue}`);
  };

  const mixpanelTracking = (tab, location, name, id) => {
    Mixpanel.track(`Accessed ${constants.tabs[tab]}`, {
      courseName: name,
      courseID: id,
      selectedTab: constants.tabs[tab],
      library: location,
    });
  };

  useEffect(() => {
    if (tab) setValue(parseInt(tab));
    if ((tab, location, name, id)) mixpanelTracking(tab, location, name, id);
  }, [tab, location, name, id]);

  // useEffect(() => {
  //   if (isHighlightingActive && dispatch) {
  //     dispatch(learningResourcesHighlightActions.toggleHighlightState());
  //   }
  // }, [isHighlightingActive, dispatch]);

  useEffect(() => {
    if (isHighlightingActive) {
      dispatch(learningResourcesHighlightActions.toggleHighlightState());
    }
  }, [tab]);

  const [showBot, setShowBot] = React.useState(false);

  const HandleBotClick = () => {
    setShowBot(!showBot);

    Mixpanel.track('Chatbot (Widget) Clicked', {
      page: 'Physics course',
    });
  };

  const steps = [
    {
      target: `.tab-0`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>View the course syllabus and learning objectives.</p>
        </>
      ),
      disableBeacon: true,
    },
    {
      target: `.tab-1`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>Create and organize your own study notes.</p>
        </>
      ),
    },
    {
      target: `.tab-2`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>
            Access your assessment scores, feedback, and performance trends.
          </p>
        </>
      ),
    },
    {
      target: `.tab-3`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>Experience real-world scenarios through simulations.</p>
        </>
      ),
    },
    {
      target: `.tab-4`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>
            Access your assessment scores, feedback, and performance trends.
          </p>
        </>
      ),
    },
    {
      target: `.tab-5`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>Track your learning progress and performance.</p>
        </>
      ),
    },
    {
      target: `.tab-6`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>Simulations.</p>
        </>
      ),
    },
    {
      target: `.sidebar-help`,
      content: (
        <>
          <Step>Step {activeStep}:</Step>
          <br />
          <p>Get help and support.</p>
        </>
      ),
    },
  ];

  const guided = JSON.parse(localStorage.getItem('guided'));

  return (
    <>
      <Wrapper>
        <TabContext value={tabValue}>
          <BoxWrapper sx={{ display: 'block', marginBottom: '-10px' }}>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              left
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ style: { background: tabIndicatorColor } }}
            >
              {!guided && (
                <Joyride
                  steps={steps}
                  continuous
                  disableScrolling
                  run={true}
                  showSkipButton
                  hideBackButton
                  hideCloseButton
                  styles={{
                    options: {
                      zIndex: 10000,
                      width: '240px',
                    },

                    tooltip: {
                      borderRadius: '12px',
                    },

                    tooltipContent: {
                      padding: '12px !important',
                      fontSize: '12px',
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      background: 'white',
                      textAlign: 'start',
                    },

                    buttonSkip: {
                      background: '#F6F5F5',
                      width: '104px',
                      height: '32px',
                      textAlign: 'center',
                      color: '#484642',
                      fontSize: '12px',
                      fontWeight: 600,
                      fontFamily: 'Inter',
                      marginRight: '8px',
                    },

                    buttonNext: {
                      background: '#E015A2',
                      width: '104px',
                      height: '32px',
                      textAlign: 'center',
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: 600,
                      fontFamily: 'Inter',
                    },
                  }}
                  locale={{
                    next: `Next (${activeStep}/${steps?.length})`,
                    last: `Finish (${activeStep}/${steps?.length})`,
                  }}
                  callback={(data) => {
                    const { index, status } = data;
                    setStatus(status);

                    setActiveStep(index + 1);

                    if (status === 'finished' || status === 'skipped') {
                      console.log('api call');
                      localStorage.setItem('guided', true);
                    }
                  }}
                />
              )}

              {tabs.map((tab, index) => {
                const isActive =
                  status === 'running' && activeStep - 1 === index;

                return (
                  <AntTab
                    id={`tab-${index}`}
                    icon={
                      <tab.icon
                        isMobile={true}
                        color={
                          tabValue === tab.value || isActive
                            ? pallete.colors.secondaryModified
                            : '#11263CB2'
                        }
                      />
                    }
                    key={index}
                    label={tab.label}
                    value={tab.value}
                    selectedtabcolor={pallete.colors.secondaryModified}
                    active={tabValue === tab.value || isActive}
                    className={`tab-${index}`}
                    isTooltip={isActive}
                  />
                );
              })}
            </TabList>
          </BoxWrapper>
          {tabs.map((tab, index) => (
            <TabPanel value={tab.value} key={index}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Wrapper>

      <IframeComp
        showBot={showBot}
        HandleBotClick={HandleBotClick}
        url={'https://www.chatbase.co/chatbot-iframe/-_xqd7Unl_jkApvtNgaLJ'}
      />
    </>
  );
};

export default Secondary;
Secondary.propTypes = {
  tabIndicatorColor: PropTypes.string,
  selectedtabcolor: PropTypes.string,
};
Secondary.defaultProps = {
  tabIndicatorColor: '#fff',
  selectedtabcolor: '#624BA2',
};
