import React from 'react';

const BotWidget = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="url(#paint0_linear_21_7)" />
      <path
        d="M33.569 10.8858C33.4194 10.1379 32.7628 9.59961 32.0001 9.59961C31.2374 9.59961 30.5807 10.1379 30.4311 10.8858L30.0495 12.7939C29.9228 13.4273 29.4278 13.9224 28.7944 14.0491L26.8863 14.4307C26.1384 14.5803 25.6001 15.2369 25.6001 15.9996C25.6001 16.7623 26.1384 17.419 26.8863 17.5685L28.7944 17.9502C29.4278 18.0768 29.9228 18.5719 30.0495 19.2053L30.4311 21.1134C30.5807 21.8613 31.2374 22.3996 32.0001 22.3996C32.7628 22.3996 33.4194 21.8613 33.569 21.1134L33.9506 19.2053C34.0773 18.5719 34.5724 18.0768 35.2058 17.9502L37.1139 17.5685C37.8617 17.419 38.4001 16.7623 38.4001 15.9996C38.4001 15.2369 37.8617 14.5803 37.1139 14.4307L35.2058 14.0491C34.5724 13.9224 34.0773 13.4273 33.9506 12.7939L33.569 10.8858Z"
        fill="white"
      />
      <path
        d="M19.118 17.0936C18.9002 16.4403 18.2888 15.9996 17.6001 15.9996C16.9114 15.9996 16.3 16.4403 16.0822 17.0936L14.9881 20.3758C14.8289 20.8535 14.454 21.2284 13.9762 21.3877L10.6941 22.4817C10.0408 22.6995 9.60007 23.3109 9.60007 23.9996C9.60007 24.6883 10.0408 25.2997 10.6941 25.5175L13.9762 26.6115C14.454 26.7708 14.8289 27.1457 14.9881 27.6235L16.0822 30.9056C16.3 31.5589 16.9114 31.9996 17.6001 31.9996C18.2888 31.9996 18.9002 31.5589 19.118 30.9056L20.212 27.6235C20.3713 27.1457 20.7462 26.7708 21.2239 26.6115L24.506 25.5175C25.1594 25.2997 25.6001 24.6883 25.6001 23.9996C25.6001 23.3109 25.1594 22.6995 24.506 22.4817L21.2239 21.3877C20.7462 21.2284 20.3713 20.8535 20.212 20.3758L19.118 17.0936Z"
        fill="white"
      />
      <path
        d="M30.318 29.8936C30.1002 29.2403 29.4888 28.7996 28.8001 28.7996C28.1114 28.7996 27.5 29.2403 27.2822 29.8936L26.9881 30.7757C26.8289 31.2535 26.454 31.6284 25.9762 31.7877L25.0941 32.0817C24.4408 32.2995 24.0001 32.9109 24.0001 33.5996C24.0001 34.2883 24.4408 34.8997 25.0941 35.1175L25.9762 35.4115C26.454 35.5708 26.8289 35.9457 26.9881 36.4235L27.2822 37.3056C27.5 37.9589 28.1114 38.3996 28.8001 38.3996C29.4888 38.3996 30.1002 37.9589 30.318 37.3056L30.612 36.4235C30.7713 35.9457 31.1462 35.5708 31.6239 35.4115L32.506 35.1175C33.1594 34.8997 33.6001 34.2883 33.6001 33.5996C33.6001 32.9109 33.1594 32.2995 32.506 32.0817L31.6239 31.7877C31.1462 31.6284 30.7713 31.2535 30.612 30.7758L30.318 29.8936Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21_7"
          x1="1.2"
          y1="-12.2782"
          x2="18.1983"
          y2="54.0111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#634DA0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BotWidget;
