import React from 'react';

const QuestionThree = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0C4.97301 0 0.5 4.4725 0.5 10C0.5 15.5269 4.9725 20 10.5 20C16.027 20 20.5 15.5275 20.5 10C20.5 4.47301 16.0275 0 10.5 0ZM10.2041 14.6804C9.63809 14.6804 9.20371 14.2066 9.20371 13.6668C9.20371 13.114 9.65125 12.6533 10.2041 12.6533C10.757 12.6533 11.2176 13.114 11.2176 13.6669C11.2176 14.2065 10.7701 14.6804 10.2041 14.6804ZM11.7046 9.56C10.9807 10.126 10.9675 10.5209 10.9675 11.2054C10.9675 11.4555 10.8359 11.7451 10.1909 11.7451C9.65117 11.7451 9.46695 11.5476 9.46695 10.8632C9.46695 9.73113 9.96715 9.19145 10.3489 8.86238C10.7832 8.49379 11.5204 8.08578 11.5204 7.375C11.5204 6.76945 10.9939 6.47988 10.3357 6.47988C8.99309 6.47988 9.2827 7.49348 8.57184 7.49348C8.21645 7.49348 7.78207 7.25648 7.78207 6.74316C7.78207 6.03238 8.59816 4.9793 10.3752 4.9793C12.06 4.9793 13.1789 5.91391 13.1789 7.15121C13.1789 8.38852 12.06 9.28359 11.7046 9.56Z"
        fill="url(#paint0_linear_85_5175)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_85_5175"
          x1="10.5"
          y1="0"
          x2="10.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionThree;
