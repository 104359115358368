import { styled } from '@mui/material';

export const IFrameWrapper = styled('div')(({ theme, showBot, fullView }) => ({
  display: `${showBot ? 'block' : 'none'}`,
  overflowY: 'scroll',
  border: 'none',
  position: 'fixed',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow:
    'rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px',
  bottom: '85px',
  right: '1rem',
  width: fullView ? 'calc(100% - 90px)' : '640px',
  height: '85vh',
  maxHeight: '824px',
  borderRadius: '0.75rem',
  zIndex: '999999999',
  overflow: 'hidden',
  left: 'unset',
  background: 'white',
  [theme.breakpoints.down('sm')]: {
    width: fullView ? 'calc(100% - 90px)' : '320px',
  },
}));

export const IFrameIconWrapper = styled('div')(() => ({
  position: 'fixed',
  bottom: '1rem',
  right: '1rem',
  cursor: 'pointer',
  display: `block`,
  zIndex: '999999999',
  transition: 'all 0.2s ease-in-out 0s',
  left: 'unset',
  transform: 'scale(1.08)',
}));
