import { display, fontFamily, fontSize, styled, width } from '@mui/system';

export const Container = styled('div')(({ messages, isWidget }) => ({
  display: 'flex',
  justifyContent: isWidget ? 'start' : 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  background: '#FFFFFF1F',
  margin: !isWidget && '10px',
  padding: isWidget ? '12px' : '0px',
  borderRadius: '12px',
  minHeight: isWidget ? '70vh' : '85vh',
  boxShadow: !isWidget && ' 0px 4px 13px 0px #0000000F',
  position: 'relative',
  paddingBottom: isWidget ? '0px' : messages?.length > 0 ? '150px' : '0px',
}));

export const Banner = styled('div')(({ isWidget, messages, typing }) => ({
  height: '52px',
  // background: 'linear-gradient(0deg, #390D5E 0%, rgba(57, 13, 94, 0.88) 100%)',
  borderRadius: '12px  12px 0px 0px',
  padding: '12px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '15px',
  minWidth: '97.5%',
  position: 'absolute',
  top: 0,
}));

export const BannerContent = styled('div')(({ isWidget }) => ({
  color: 'white',
  fontFamily: 'Inter',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: isWidget ? '10px' : '15px',
  marginBottom: isWidget && '10px',
  padding: isWidget && '0px 5px',
}));

export const BannerIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const BannerIcon = styled('img')(({ theme, isWidget }) => ({
  cursor: 'pointer',
  marginRight: '5px',
  [theme.breakpoints.down('sm')]: {
    display: isWidget && 'none',
  },
}));

export const BannerHeading = styled('h1')(({ isWidget, theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  padding: 0,
  margin: 0,

  [theme.breakpoints.down('sm')]: {
    fontSize: isWidget && '16px',
  },
}));

export const BannerDetails = styled('p')(() => ({
  fontSize: '12px',
  fontWeight: 400,
  padding: 0,
  margin: 0,
}));

export const Wrapper = styled('div')(
  ({ messages, theme, isWidget, typing }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    fontFamily: 'Inter',
    position: 'relative',
    paddingTop: isWidget ? '0px' : messages?.length > 0 ? '0px' : '0px',
    width: isWidget ? '100%' : '65%',
    minHeight: isWidget ? '60vh' : '70vh',
    maxHeight: isWidget && '60vh',
    overflowY: isWidget && 'auto',
    overflowX: isWidget && 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const HeadingWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Heading = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 600,
  color: '#475467',
  fontFamily: 'Inter',
  paddingTop: 25,
  width: 'fit-content',
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  animation: 'typing 8s forwards',
  display: 'inline-block',

  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
  },

  '@keyframes typing': {
    from: { width: '0%' },
    to: { width: '100%' },
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60vw',
  height: '30vh',

  '@media (min-height: 650px)': {
    width: '60vw',
    height: '40vh',
  },
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const HistoryWrapper = styled('div')(() => ({
  background: '#f4f4f4',
  width: '30%',
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '10px',
}));

export const InputContainer = styled('div')(
  ({ messages, theme, isWidget, fullView }) => ({
    position: 'fixed',
    bottom: isWidget ? '92px' : '0vh',
    minHeight: messages?.length > 0 ? '8vh' : '13vh',
    width: fullView
      ? 'calc(100% - 92px)'
      : isWidget
      ? '640px'
      : 'calc(100% - 100px)',
    right: fullView ? '17px' : isWidget ? '17px' : '13px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    background: isWidget ? 'white' : 'rgb(249 249 252)',
    paddingBottom: '10px',

    [theme.breakpoints.down('sm')]: {
      left: !isWidget && 0,
      width: isWidget && '320px',
    },
  })
);

export const InputWrapper = styled('div')(
  ({ theme, isWidget, fullView, active }) => ({
    display: 'flex',
    gap: fullView ? '10px' : '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: active ? 'white' : '#F6F6F6',
    borderRadius: '12px',
    minWidth: 'fit-content',
    width: fullView ? '95%' : isWidget ? '80%' : '60%',
    maxHeight: '150px',
    padding: '0px 8px 0px 16px',
    border: active ? '2px solid #5D5D5D' : '2px solid #EAECF0',
    overflow: 'hidden',
    // order: isWidget ? 2 : 1,

    [theme.breakpoints.down('sm')]: {
      width: '80%',
      gap: '10px',
      justifyContent: 'center',
    },
  })
);

export const Input = styled('textarea')(
  ({ theme, showScroll, isWidget, fullView, active }) => ({
    minWidth: '70%',
    outline: 'none',
    background: active ? 'white' : '#F6F6F6',
    border: 'none',
    resize: 'none',
    color: 'black',
    fontFamily: 'Inter',
    textAlign: 'start',
    fontSize: '16px',
    minHeight: 'fit-content',
    maxHeight: '120px',
    overflowY: showScroll ? 'auto' : 'hidden',
    padding: '8px',

    '::placeholder': {
      color: '#3D3D3D',
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Inter',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '70%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: fullView ? '85%' : isWidget ? '76%' : '83%',
    },
  })
);

export const Button = styled('button')(({ enable }) => ({
  outline: 'none',
  border: ' 1px solid var(--Neutral-100, #E7E7E7)',
  borderRadius: '8px',
  lineHeight: '20px',
  cursor: enable ? 'pointer' : 'not-allowed',
  fontFamily: 'Inter',
  padding: '8px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  color: '#454545',
  fontSize: '14px',
  fontWeight: 500,
  minWidth: 'fit-content',
  background: 'white',

  '&:hover': {
    background: '#f4f4f4',
  },
}));

export const GeneratedQuestionsWrapper = styled('div')(
  ({ theme, isWidget, fullView }) => ({
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '22px',
    marginBottom: '8px',
    width: fullView ? '80%' : isWidget ? '640px' : '100%',
    // order: isWidget ? 1 : 2,

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      overflowX: 'auto',
      flexWrap: isWidget && 'nowrap',
      width: isWidget && '320px',
    },
  })
);

export const QuestionContainer = styled('div')(({ messages, isWidget }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: isWidget ? 'start' : 'end',
  alignItems: 'start',
  gap: '16px',
  // paddingTop: isWidget ? '10px' : messages?.length === 1 ? '60px' : '0px',
  margin: '0px -10px',
}));

export const Question = styled('div')(({ isWidget, fullView }) => ({
  background: '#FEE5F9',
  maxWidth: fullView ? '87%' : isWidget ? '84%' : '90%',
  // padding: isWidget ? '12px' : '23px 12px',
  borderRadius: '10px',
  color: 'black',
  wordBreak: 'break-word',
  width: 'fit-content',
  fontFamily: 'Inter',
  fontSize: isWidget ? '14px' : '16px',
  boxShadow: '4px 4px 4px 0px #00000040',
  fontWeight: 400,
  order: isWidget ? 2 : 1,
  padding: '12px',
}));

export const TypingContainer = styled('div')(() => ({
  background: 'white',
  borderRadius: '10px',
  color: '#454545',
  width: 'fit-content',
  padding: '12px 24px',
  fontSize: '15px',
  fontFamily: 'Inter',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  boxShadow: ' 4px 4px 4px 0px #B2B2B2',
}));

export const AnswerContainer = styled('div')(({ alignItems }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'start',
}));

export const Answer = styled('pre')(({ isWidget }) => ({
  maxWidth: '90%',
  margin: 0,
  borderRadius: '12px',
  color: 'black',
  whiteSpace: 'pre-wrap',
  width: 'fit-content',
  fontFamily: 'Inter',
  lineHeight: '30px',
  fontSize: isWidget ? '14px' : '16px',
  background: isWidget ? '#F6F6F6' : 'white',
  padding: '12px',
}));

export const BoldText = styled('b')(() => ({
  fontWeight: '700',
  fontSize: '16px',
}));

export const SendIcon = styled('img')(({ enable }) => ({
  height: '30px',
  width: '30px',
  cursor: enable ? 'pointer' : 'not-allowed',
}));

export const BotIcon = styled('img')(() => ({
  height: '100%',
  width: '100%',
}));

export const ProfileImg = styled('img')(() => ({
  minHeight: '48px',
  width: '48px',
  borderRadius: '100%',
}));

export const LogoImg = styled('img')(() => ({
  width: '100%',
  height: '100%',
}));

export const QuestionImageWrapper = styled('div')(({ isWidget }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: isWidget ? 0 : '4px',
  order: isWidget ? 1 : 2,
}));

export const UserName = styled('span')(() => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 500,
}));

export const TypingWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
}));

export const LoaderWrapper = styled('div')(() => ({
  marginTop: '80px',
}));

export const ResetChatWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '20px',
  width: '90%',
  position: 'fixed',
  top: '88px',
  right: '10px',
}));

export const ExpandChatWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '20px',
  padding: '16.5px 20px',
  width: '100%',
}));

export const StopIcon = styled('div')(() => ({
  height: '17px',
  width: '17px',
  background: '#FdFdFd',
}));

export const SendWrapper = styled('div')(({ active }) => ({
  cursor: active ? 'pointer' : 'not-allowed',
  height: '48px',
  minWidth: '48px',
  margin: '4px 0px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: active ? '#58458E' : '#888888',
  border: '1px solid #888888',

  '&:hover': {
    background: '#493D72',
    border: '1px solid #624BA2',
  },
}));
