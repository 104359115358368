import React, { useEffect, useRef, useState } from 'react';
import Stack from '../../../UI/Stack/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../../redux/reducers/auth.js';
import api from './../../../../Services';
import { Link, useNavigate } from 'react-router-dom';
import defaultImg from './../../../../assets/images/user/default.png';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  StyledNav,
  NavContent,
  Img,
  UserName,
  UserCategory,
  ImgContainer,
  StackWrapper,
  IconButtonWrapper,
  Banner,
  BannerContent,
  BannerLink,
  BannerButton,
  BannerWrapper,
  ProfileWrapper,
  ProfileModal,
  ProfileModalSection,
  ProfileModalTitle,
} from './Nav.style';
import Loader from './../../../UI/Loader/Loader';
import { userActions } from '../../../../redux/reducers/user';
import ToolTip from './../../../UI/Tooltip/ToolTip';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import drawer from '../../../../assets/images/nav/drawer.svg';
import { Mixpanel } from '../../../../helpers/Mixpanel.js';
import ArrowDown from '../../../../assets/images/user/ArrowDown.png';
import Profile from '../../../../assets/images/user/Profile.png';
import Logout from '../../../../assets/images/user/Logout.png';
import Settings from '../../../../assets/images/user/Settings.png';

const Nav = ({ appbarheight, isXs, setDrawerWidth, drawerWidth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = (e) => {
    Mixpanel.track('Logged Out');
    Mixpanel.reset(); // to handle multiple users on the same device

    e.preventDefault();
    dispatch(authActions.loginHandler({ loggedIn: 'false' }));
    dispatch(userActions.logOutHandler());
    localStorage.removeItem('auth');
    localStorage.removeItem('attemptedQuestions');
    return navigate('/auth', { replace: true });
  };

  const userData = useSelector((state) => state.user);

  const { isQuestion } = useSelector((state) => state.assessment);

  const [loading, setLoading] = React.useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const modalRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest('.pm')
    ) {
      setShowProfile(false);
    }
  };

  const fullUserNameCallFromDB = async (token) => {
    let temp = await api.getUser(token);
    return temp;
  };
  const apiCall = async () => {
    let token = localStorage.getItem('auth');
    fullUserNameCallFromDB(token)
      .then((el) => {
        dispatch(userActions.getUserData(el.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const loadInitialData = () => {
    apiCall();
  };
  useEffect(() => {
    setLoading(true);
    loadInitialData();
  }, []);

  useEffect(() => {
    if (showProfile) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showProfile]);
  return (
    <>
      <StyledNav appbarheight={appbarheight} isQuestion={isQuestion}>
        <Banner
          onClick={() => {
            Mixpanel.track('Selected Referral banner');

            navigate('/user');
          }}
        >
          <BannerContent>
            Paid plans launching soon — refer friends now to unlock free access!
          </BannerContent>
        </Banner>

        <NavContent
          direction='row'
          justifyContent={drawerWidth === '0px' ? 'space-between' : 'flex-end'}
          alignItems='center'
        >
          {isXs && drawerWidth === '0px' ? (
            <Img
              src={drawer}
              onClick={() => setDrawerWidth('75px')}
              sx={{
                width: '25px',
                height: '16px',
                borderRadius: '0px',
                marginLeft: '20px !important',
              }}
            />
          ) : null}

          <StackWrapper
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            {loading ? (
              <Loader />
            ) : (
              <ProfileWrapper
                onClick={() => {
                  setShowProfile(!showProfile);
                }}
                className='pm'
              >
                <ImgContainer>
                  <Img
                    src={userData?.img ? userData.img : defaultImg}
                    alt='user-dp'
                  />
                </ImgContainer>

                <UserName variant='h6'>{userData?.fName}</UserName>

                <img src={ArrowDown} alt='Expand' />

                {showProfile && (
                  <ProfileModal ref={modalRef}>
                    <Link
                      to='/user'
                      onClick={() => {
                        Mixpanel.track('Selected My profile');
                      }}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <ProfileModalSection>
                        <img src={Profile} alt='Profile' />

                        <ProfileModalTitle>My Profile</ProfileModalTitle>
                      </ProfileModalSection>
                    </Link>

                    <Link
                      to='/settings'
                      onClick={() => {
                        Mixpanel.track('Selected Settings', {
                          page: 'Settings',
                        });
                      }}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <ProfileModalSection>
                        <img src={Settings} alt='Settings' />

                        <ProfileModalTitle>Settings</ProfileModalTitle>
                      </ProfileModalSection>
                    </Link>

                    <ProfileModalSection onClick={logoutHandler}>
                      <img
                        src={Logout}
                        alt='Logout'
                        style={{
                          marginLeft: '3px',
                        }}
                      />

                      <ProfileModalTitle>Log out</ProfileModalTitle>
                    </ProfileModalSection>
                  </ProfileModal>
                )}
              </ProfileWrapper>

              // <ToolTip
              //   title="User Profile"
              //   backgroundColor="#251038"
              //   arrowColor="#251038"
              // >
              //   <Link
              //     to="/user"
              //     onClick={() => {
              //       Mixpanel.track('Selected My profile', {
              //         page: 'Profile',
              //       });
              //     }}
              //   >

              //   </Link>
              // </ToolTip>
            )}

            {/* <Stack direction="column">
              <UserCategory style={{ marginTop: '0px' }} variant="subtitle2">
                Student
              </UserCategory>
            </Stack>
            <ToolTip
              title="Logout"
              backgroundColor="#251038"
              arrowColor="#251038"
            >
              <IconButtonWrapper onClick={logoutHandler}>
                <LogoutIcon sx={{ color: '#E015A2' }} />
              </IconButtonWrapper>
            </ToolTip> */}
          </StackWrapper>
        </NavContent>
      </StyledNav>
    </>
  );
};

export default Nav;
