import React from 'react';

const ArrowDownIcon = ({ ...props }) => {
  return (
    // <svg
    //   width={props?.isProfile ? '17' : '9'}
    //   height={props?.isProfile ? '13' : '6'}
    //   viewBox="0 0 9 6"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     d="M9 1.06123L7.93874 1.39168e-07L4.5 3.43874L1.06126 1.04104e-06L1.09284e-06 1.06123L4.5 5.56123L9 1.06123Z"
    //     fill={props?.isProfile ? '#624BA2' : '#E015A2'}
    //   />
    // </svg>

    <svg
      width='12'
      height='7'
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.30983 6.30959L6.30981 6.30961C6.26918 6.35029 6.22093 6.38256 6.16781 6.40458C6.1147 6.4266 6.05777 6.43793 6.00028 6.43793C5.94278 6.43793 5.88585 6.4266 5.83274 6.40458C5.77963 6.38256 5.73138 6.35029 5.69075 6.30961L5.69072 6.30959L0.690722 1.30959C0.608623 1.22749 0.5625 1.11614 0.5625 1.00003C0.5625 0.883928 0.608623 0.772577 0.690722 0.690478C0.772821 0.608379 0.884172 0.562256 1.00028 0.562256C1.11638 0.562256 1.22773 0.608377 1.30983 0.690475C1.30983 0.690476 1.30983 0.690477 1.30983 0.690478L5.95608 5.33735L6.00028 5.38155L6.04448 5.33735L10.6907 0.690475C10.7314 0.649826 10.7796 0.61758 10.8327 0.59558C10.8859 0.573579 10.9428 0.562256 11.0003 0.562256C11.0578 0.562256 11.1147 0.573579 11.1678 0.59558C11.2209 0.61758 11.2692 0.649826 11.3098 0.690478C11.3505 0.731129 11.3827 0.779389 11.4047 0.832504C11.4267 0.885617 11.4381 0.942544 11.4381 1.00003C11.4381 1.05752 11.4267 1.11445 11.4047 1.16756C11.3827 1.22068 11.3505 1.26894 11.3098 1.30959L6.30983 6.30959Z'
        fill='#5D5D5D'
        stroke='#5D5D5D'
        stroke-width='0.125'
      />
    </svg>
  );
};

export default ArrowDownIcon;
