import Filter from './components/Filter';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import 'react-date-picker/dist/DatePicker.css';
import BreadCrumb from '../../../../BreadCrumb';
import api from '../../../../../../../Services';
import ReviewScreen from './screens/ReviewScreen';
import ResultScreen from './screens/ResultScreen';
import { Box, useMediaQuery } from '@mui/material';
import { QuestionModal } from './Assessment.style';
import React, { useEffect, useState } from 'react';
import QuestionScreen from './screens/QuestionScreen';
import GridComp from '../../../../../../UI/Grid/Grid';
import CardComp from '../../../../../../UI/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeScreen from './components/WelcomeScreen';
import LeftSide from '../../../../LeftSideBar/LeftSide';
import Loader from '../../../../../../UI/Loader/Loader';
import AssessmentScreen from './screens/AssessmentScreen';
import MobileDetector from '../../../../../../../helpers/mobileDetector';
import useUserResponseAwait from '../../../../hooks/useUserResponseAwait';
import MobileBottomBar from '../../../../../../mobileBottomBar/MobileBottomBar';
import { assessmentAction } from '../../../../../../../redux/reducers/assessment';
import MobileCourseIndicator from '../../../../../../mobileBottomBar/MobileCourseIndicator';

const Assessment = () => {
  const params = useParams();
  const { id: courseId } = params;
  const [newSelection, setNewSelection] = useState(null);
  const [assessmentView, setAssessmentView] = useState(false);
  const [hierarchyData, setHeirarchyData] = useState(null);
  const [hasAssessment, setHasAssessment] = useState(true);
  const [activeScreen, setActiveScreen] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [userAttemptedQuestins, setUserAttemptedQuestions] = useState([]);
  const [structuredData, setStructuredData] = useState([]);
  const isOctilearn = window.location.pathname.includes('octilearnlibrary');
  const [initialData, setinitialData] = useState(null);
  const [isRedirected, setIsRedirected] = useState(false);
  const urlSearchString = window.location.search;
  const paramData = new URLSearchParams(urlSearchString);
  const [updateData, setUpdateData] = useState(false);
  const [activeSnack, setActiveSnack] = useState(null);
  const [chapterId, setChapterId] = useState(null);

  const [questionsBoard, setQuestionsBoard] = useState({
    totalQuestions: 0,
    attemptedQuestions: 0,
    remainingQuestions: null,
  });
  const [openMobileBar, setOpenMobileBar] = useState(false);
  const { isMobile } = MobileDetector();
  const { awaitingUserResponse, toggleLoading } = useUserResponseAwait();
  const [allQuestions, setAllQuestions] = useState(null);
  const [attemptedQuestions, setAttemptedQuestions] = useState(null);
  const [allQuesitonsData, setAllQuestionsData] = useState(null);
  const [completionModal, setCompletionModel] = useState(false);
  const [updatedHierarchy, setUpdatedHierarchy] = useState(null);

  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);
  const user = useSelector((state) => state.user);

  const initialLImit = new Date().getFullYear() - 15;
  const handleOpenMobileBar = () => {
    setOpenMobileBar(!openMobileBar);
  };
  const [filter, setFiler] = useState({
    from: initialLImit?.toString(),
    to: new Date(2023, 11, 31),
  });
  const [updatedFilter, setUpdatedFilter] = useState({
    from: 2010,
    to: 2023,
  });

  const userId = useSelector((state) => state.user.id);

  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');

  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;

  const handleExpansion = (item, structure) => {
    switch (item.type) {
      case 'unit':
        const updatedData = structure.map((unit) => {
          if (unit.id === item.id) {
            return {
              ...unit,
              isExpanded: !unit.isExpanded,
              nested: unit.childrenElements,
            };
          }
          return { ...unit, isExpanded: false, nested: unit.childrenElements };
        });
        return updatedData;
        break;

      case 'chapter':
        if (item.unitId) {
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element) => {
                if (element.id === item.id) {
                  return {
                    ...element,
                    isExpanded: !element.isExpanded,
                    nested: element.childrenElements,
                  };
                }
                return {
                  ...element,
                  isExpanded: false,
                  nested: element.childrenElements,
                };
              });
              return {
                ...unit,
                childrenElements: modifiedChildren,
                nested: modifiedChildren,
              };
            }

            return unit;
          });
          return updatedData;
        } else {
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return {
                ...chapter,
                isExpanded: !chapter.isExpanded,
                nested: chapter.childrenElements,
              };
            }
            return {
              ...chapter,
              isExpanded: false,
              nested: chapter.childrenElements,
            };
          });
          return updatedData;
        }
        break;

      case 'snack':
        const updatedDataSnack = structure.map((unit) => {
          if (unit.id === item.unitId) {
            let modifiedUnitsChildren = unit.childrenElements.map((element) => {
              if (element.id === item.chapterId) {
                let modifiedSnacksChildren = element.childrenElements.map(
                  (snackElements) => {
                    if (snackElements.id === item.id) {
                      return {
                        ...snackElements,
                        isExpanded: !snackElements.isExpanded,
                        nested: snackElements.childrenElements,
                      };
                    }
                    return snackElements;
                  }
                );
                return {
                  ...element,
                  childrenElements: modifiedSnacksChildren,
                  nested: modifiedSnacksChildren,
                };
              }
              return element;
            });
            return {
              ...unit,
              childrenElements: modifiedUnitsChildren,
              nested: modifiedUnitsChildren,
            };
          }

          return unit;
        });
        return updatedDataSnack;
      default:
        console.log('Unknown item type');
    }
  };

  //  Fetch Chapter Hierarchy
  const fetchAssessmentHierarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForAssessment(token, courseId)
      .then((resp) => {
        setHeirarchyData(resp);
      })
      .catch((err) => console.error(err));
  };

  // Get Quiz Questions
  const getAllQuestions = async (id, type) => {
    toggleLoading(true);

    let token = localStorage.getItem('auth');
    await api
      .getQuestions(token, id, updatedFilter, type)
      .then((res) => {
        setAllQuestions(res?.data);
        toggleLoading(false);
        getUserAttemptQuestions(res?.data);
        setHasAssessment(false);
      })
      .catch((err) => console.error(err));
  };

  function handleSelectedData(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }

  // function handleSelection(e) {
  //   const assessment = handleExpansion(e, structuredData);
  //   const assessmentGlobal = {
  //     data: assessment,
  //     tab: 'assessment',
  //     selection: e,
  //   };
  //   localStorage.setItem(
  //     'assessmentGlobalState',
  //     JSON.stringify(assessmentGlobal)
  //   );
  //   handleSelectedData(e);
  // }

  const clearURLQueryParams = () => {
    const url = new URL(window.location);
    url.searchParams.delete('id');
    url.searchParams.delete('unitId');
    url.searchParams.delete('chapterId');
    url.searchParams.delete('label');
    url.searchParams.delete('name');
    window.history.replaceState(null, '', url);
  };

  function handleSelection(e) {
    setIsRedirected(false);
    clearURLQueryParams();
    localStorage.removeItem('assessmentGlobalState');

    const assessment = handleExpansion(e, structuredData);

    const updatedData = assessment?.map((item) => {
      return {
        ...item,
        courseId: courseId,
      };
    });

    const updatedSelection = {
      ...e,
      courseId,
    };

    const assessmentGlobal = {
      data: updatedData,
      tab: 'assessment',
      selection: updatedSelection,
    };

    localStorage.setItem(
      'assessmentGlobalState',
      JSON.stringify(assessmentGlobal)
    );

    handleSelectedData(e);
  }

  const listItemClickHandler = (selectedListItem, isStorage = false) => {
    if (selectedListItem?.type === 'chapter') {
      setChapterId(selectedListItem?.id);
    }

    if (isStorage) {
      if (selectedListItem.isExpanded) {
        getAllQuestions(selectedListItem?.id, selectedListItem?.type);
        setHasAssessment(false);
      }
      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        const firstLevelData = structuredData?.filter((unit) => {
          return unit.id === selectedListItem.unitId;
        });
        firstLevelData[0].nested = firstLevelData[0]?.childrenElements;
        getAllQuestions(firstLevelData[0]?.id, firstLevelData[0]?.type);
        setActiveItem(firstLevelData[0]?.label);
        setNewSelection(firstLevelData[0]);
      } else {
        setActiveItem(selectedListItem.label);
        handleSelectedData(selectedListItem);
        setNewSelection(selectedListItem);
      }

      if (selectedListItem.type === 'snack') {
        addUserRecentActivity(selectedListItem);
      }
    } else {
      if (selectedListItem.isExpanded) {
        getAllQuestions(selectedListItem?.id, selectedListItem?.type);
        setHasAssessment(false);
      }
      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        const firstLevelData = structuredData?.filter((unit) => {
          return unit.id === selectedListItem.unitId;
        });
        firstLevelData[0].nested = firstLevelData[0]?.childrenElements;
        getAllQuestions(firstLevelData[0]?.id, firstLevelData[0]?.type);
        setActiveItem(firstLevelData[0]?.label);
        setNewSelection(firstLevelData[0]);
      } else {
        handleSelection(selectedListItem);
        setActiveItem(selectedListItem.label);
        handleSelectedData(selectedListItem);
        setNewSelection(selectedListItem);
      }

      if (selectedListItem.type === 'snack') {
        addUserRecentActivity(selectedListItem);
      }
    }
  };

  // Get User Quiz Questions
  const getUserAttemptQuestions = async (data) => {
    toggleLoading(true);

    let token = localStorage.getItem('auth');
    await api
      .getUserQuestions(token)
      .then((res) => {
        // Set User Total Questions
        setQuestionsBoard((prev) => {
          return {
            ...prev,
            totalQuestions: data,
          };
        });

        const attemptedQuestions = res?.data?.filter((item) => {
          return data?.some((q) => q?.id === item?.questionId);
        });

        // Set user attempted questions
        setQuestionsBoard((prev) => {
          return {
            ...prev,
            attemptedQuestions: attemptedQuestions,
          };
        });

        // Set User remaining questions
        const newQuestions = data?.filter((item) => {
          return !res?.data?.some((q) => {
            return q?.questionId === item?.id;
          });
        });

        if (activeScreen === 0) {
          setAllQuestions(newQuestions);
        }

        setQuestionsBoard((prev) => {
          return {
            ...prev,
            remainingQuestions: newQuestions,
          };
        });

        if (activeScreen !== 1) {
          // setUserAttemptedQuestions([]);
        }

        toggleLoading(false);
      })
      .catch((err) => console.error(err));
  };

  // Add user submitted quiz

  const addEditQuiz = async () => {
    if (userAttemptedQuestins?.length > 0) {
      let token = localStorage.getItem('auth');
      const response = await api
        .addEditUserQuiz(token, userAttemptedQuestins)
        .then(() => {
          localStorage.removeItem('attemptedQuestions');
        });
    }
  };

  // Update Top Bar Z-Index

  const updateZIndex = (bool) => {
    dispatch(assessmentAction.updateTopBarZIndex(bool));
  };

  useEffect(() => {
    fetchAssessmentHierarchy();
  }, []);

  useEffect(() => {
    if (newSelection !== null) {
      getAllQuestions(newSelection?.id, newSelection?.type);
    }
  }, [filter]);

  useEffect(() => {
    if (
      activeScreen === 0 &&
      questionsBoard?.remainingQuestions?.length === 0 &&
      questionsBoard?.totalQuestions?.length > 0
    ) {
      setCompletionModel(true);
    } else {
      setCompletionModel(false);
    }
  }, [questionsBoard, newSelection, activeScreen]);

  useEffect(() => {
    let q = localStorage.getItem('attemptedQuestions');

    q = JSON.parse(q);

    if (q) {
      if (q.length > 0) {
        setUserAttemptedQuestions(q);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedData?.layer === 1 && selectedData?.isExpanded === false) {
      setHasAssessment(true);
    }
  }, [selectedData]);

  const addUserRecentActivity = async (e) => {
    let token = localStorage.getItem('auth');

    const date = new Date();

    const time = date?.toLocaleTimeString('en-GB', { hour12: true });

    let utcString = date.toUTCString();

    let formattedDate = utcString.split(' ').slice(1, 4).join(' ');

    const updatedChpaterId = chapterId ? chapterId : e?.chapterId;

    const payload = {
      courseId,
      unitId: e?.unitId,
      chapterId: updatedChpaterId,
      snackId: e?.id,
      section: isOctilearn ? 'Octilearn Library' : 'My Library',
      date: formattedDate,
      time,
      tab: 'assessment',
    };

    let temp = api.userRecentActivity(payload, token).then((res) => {
      if (res?.error) {
        return console.log('error');
      }
    });
  };

  const handleExpansionFromSnack = (snackData, structuredData) => {
    const { unitId, chapterId, id: snackId } = snackData;

    if (unitId) {
      const updatedData = structuredData?.map((unit) => {
        if (unit.id === unitId) {
          const modifiedChapters = unit?.childrenElements?.map((chapter) => {
            if (chapter.id === chapterId) {
              const modifiedSnacks = chapter?.childrenElements?.map((snack) => {
                if (snack.id === snackId) {
                  return {
                    ...snack,
                    isExpanded: true,
                  };
                }
                return snack;
              });

              return {
                ...chapter,
                snacks: modifiedSnacks,
                childrenElements: modifiedSnacks,
                isExpanded: true,
              };
            }
            return {
              ...chapter,
              isExpanded: false,
            };
          });

          return {
            ...unit,
            nested: modifiedChapters,
            childrenElements: modifiedChapters,
            isExpanded: true,
            courseId: snackData?.courseId,
          };
        }

        return {
          ...unit,
          isExpanded: false,
        };
      });

      return updatedData;
    } else {
      const modifiedChapters = structuredData?.map((chapter) => {
        if (chapter.id === chapterId) {
          const modifiedSnacks = chapter?.childrenElements?.map((snack) => {
            if (snack.id === snackId) {
              return {
                ...snack,
                isExpanded: true,
              };
            }
            return snack;
          });

          return {
            ...chapter,
            snacks: modifiedSnacks,
            childrenElements: modifiedSnacks,
            isExpanded: true,
            courseId: snackData?.courseId,
          };
        }

        return {
          ...chapter,
          isExpanded: false,
        };
      });

      return modifiedChapters;
    }
  };

  function handleSelectionFromSnack(redirectSnackData, data) {
    if (isRedirected) {
      const assessment = handleExpansionFromSnack(redirectSnackData, data);

      const asessmentGlobal = {
        data: assessment,
        tab: 'assessment',
        selection: redirectSnackData,
      };

      localStorage.setItem(
        'assessmentGlobalState',
        JSON.stringify(asessmentGlobal)
      );

      handleSelectedData(redirectSnackData);

      setUpdateData(true);

      getAllQuestions(redirectSnackData?.id, 'snack');
    }
  }

  useEffect(() => {
    if (initialData && isRedirected) {
      const redirectSnackData = {
        chapterId: Number(paramData?.get('chapterId')),
        hierarchyData: undefined,
        id: Number(paramData?.get('id')),
        isExpanded: true,
        label: paramData?.get('label'),
        layer: 3,
        name: paramData?.get('name'),
        nested: undefined,
        openlist: undefined,
        setopen: undefined,
        type: 'snack',
        unitId: Number(paramData?.get('unitId')),
        courseId: courseId,
      };

      setActiveSnack(paramData?.get('label'));

      handleSelectionFromSnack(redirectSnackData, initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (paramData.get('id') !== null) {
      setIsRedirected(true);
    }
  }, [paramData]);

  // Completed Assessment Code

  const getUserCompletedAssessment = async (payload) => {
    let token = localStorage.getItem('auth');

    let temp = api.getCompletedAssessment(payload, token).then((res) => {
      if (res?.error) {
        return console.log('error');
      }

      setAttemptedQuestions(res?.data);
    });
    return temp;
  };

  const getUserAllQuestionsData = async (payload) => {
    let token = localStorage.getItem('auth');

    let temp = await api.getAllQuestionsData(token, payload).then((res) => {
      if (res?.error) {
        return console.log('error');
      }

      setAllQuestionsData(res?.data);
    });
    return temp;
  };

  useEffect(() => {
    let payload;

    if (hierarchyData?.data?.length > 0) {
      if (hierarchyData?.isUnitsAvailable) {
        const unitIds = hierarchyData.data.map((item) => item.id);

        payload = {
          courseId,
          unitId: unitIds,
          userId: user?.id,
        };

        getUserCompletedAssessment(payload);
        getUserAllQuestionsData(payload);
      } else {
        const chapterIds = hierarchyData?.data[0]?.chapters?.map(
          (item) => item?.id
        );

        if (chapterIds) {
          payload = {
            courseId,
            chapterId: chapterIds,
            userId: user?.id,
          };

          getUserCompletedAssessment(payload);
          getUserAllQuestionsData(payload);
        }
      }
    }
  }, [hierarchyData]);

  function groupQuestionsBySnack(allQuestions) {
    if (allQuestions)
      return allQuestions?.reduce((acc, question) => {
        const snackId = question?.snackId;
        if (!acc[snackId]) {
          acc[snackId] = [];
        }
        acc[snackId].push(question);
        return acc;
      }, {});
  }

  function calculateCompletionPercentage(
    hierarchyData,
    allQuesitons,
    completedQuestions
  ) {
    if (!hierarchyData || !allQuesitons || !completedQuestions)
      return hierarchyData;

    // Group questions by snackId
    const questionsBySnack = groupQuestionsBySnack(allQuesitons);

    if (questionsBySnack) {
      const doneQuestions = new Set(completedQuestions.map((q) => q.id));

      if (hierarchyData?.isUnitsAvailable) {
        hierarchyData?.data?.forEach((unit) => {
          unit.chapters?.forEach((chapter) => {
            chapter.snacks.forEach((snack) => {
              const snackQuestions = questionsBySnack[snack.id] || [];
              const totalQuestions = snackQuestions.length;

              const completedQuestionsCount = snackQuestions.filter((fc) =>
                doneQuestions.has(fc.id)
              ).length;

              // Calculate the completion percentage for each snack
              snack.completionPercentage = totalQuestions
                ? Math.floor((completedQuestionsCount / totalQuestions) * 100)
                : 0;
            });

            // Calculate chapter completion
            const totalSnacks = chapter.snacks.length || 0;
            const totalSnackCompletion = chapter.snacks.reduce(
              (acc, snack) => acc + snack.completionPercentage,
              0
            );
            chapter.completionPercentage = totalSnacks
              ? Math.floor(totalSnackCompletion / totalSnacks)
              : 0;
          });

          // Calculate unit completion

          const totalChapters = unit.chapters.length || 0;
          const totalChapterCompletion = unit.chapters.reduce(
            (acc, chapter) => acc + chapter.completionPercentage,
            0
          );
          unit.completionPercentage = totalChapters
            ? Math.floor(totalChapterCompletion / totalChapters)
            : 0;
        });

        return hierarchyData;
      } else {
        const data = hierarchyData?.data;

        data.forEach((unit) => {
          unit.chapters.forEach((chapter) => {
            chapter.snacks.forEach((snack) => {
              const snackQuestions = questionsBySnack[snack.id] || [];
              const totalQuestions = snackQuestions.length;

              const completedQuestionsCount = snackQuestions.filter((fc) =>
                doneQuestions.has(fc.id)
              ).length;

              // Calculate the completion percentage for each snack
              snack.completionPercentage = totalQuestions
                ? Math.floor((completedQuestionsCount / totalQuestions) * 100)
                : 0;
            });

            // Calculate chapter completion
            const totalSnacks = chapter.snacks.length || 0;
            const totalSnackCompletion = chapter.snacks.reduce(
              (acc, snack) => acc + snack.completionPercentage,
              0
            );
            chapter.completionPercentage = totalSnacks
              ? Math.floor(totalSnackCompletion / totalSnacks)
              : 0;
          });
        });

        return hierarchyData;
      }
    }
  }

  useEffect(() => {
    if (attemptedQuestions && allQuesitonsData) {
      const updatedHierarchy = calculateCompletionPercentage(
        hierarchyData,
        allQuesitonsData,
        attemptedQuestions
      );

      setUpdatedHierarchy(updatedHierarchy);
    }
  }, [attemptedQuestions, allQuesitonsData, hierarchyData]);

  return (
    <>
      {assessmentView ? (
        <>
          <QuestionModal>
            <QuestionScreen
              newSelection={newSelection}
              setAssessmentView={setAssessmentView}
              allQuestions={allQuestions}
              updateZIndex={updateZIndex}
              userId={userId}
              addEditQuiz={addEditQuiz}
              userAttemptedQuestins={userAttemptedQuestins}
              setUserAttemptedQuestions={setUserAttemptedQuestions}
              setActiveScreen={setActiveScreen}
            />
          </QuestionModal>
        </>
      ) : (
        <>
          <Box
            sx={{
              padding: { xs: '20px 10px', sm: '0px 0px' },
              marginBottom: { xs: '70px', sm: '0px' },
            }}
          >
            <GridComp container spacing={{ xs: 0, sm: 4 }}>
              {activeScreen === 0 && (
                <>
                  <GridComp item xs={12}>
                    <BreadCrumb />
                  </GridComp>
                </>
              )}

              {activeScreen !== 2 && (
                <GridComp
                  item
                  xs={left}
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                >
                  <CardComp nopadding={true}>
                    {!isMobile && (
                      <LeftSide
                        onListClick={(e, i) => {
                          listItemClickHandler(e, i);
                        }}
                        hierarchyData={updatedHierarchy}
                        isLibrary={false}
                        isFlashcard={false}
                        isSimulation={false}
                        isAssesment={true}
                        selectedData={selectedData}
                        activeScreen={activeScreen}
                        setActiveScreen={setActiveScreen}
                        setSelectedData={setSelectedData}
                        setStructuredData={setStructuredData}
                        tab={'assessment'}
                        setinitialData={setinitialData}
                        updatedData={updateData}
                      />
                    )}
                  </CardComp>
                </GridComp>
              )}

              <GridComp
                item
                xs={right}
                style={{ minWidth: activeScreen === 2 && '100%' }}
              >
                {hasAssessment ? (
                  <>
                    <WelcomeScreen title={course?.title} />
                    <MobileCourseIndicator tab='Assesments' />
                  </>
                ) : awaitingUserResponse?.loading ? (
                  <Loader />
                ) : (
                  <>
                    {activeScreen === 0 ? (
                      <AssessmentScreen
                        updateZIndex={updateZIndex}
                        newSelection={newSelection}
                        updatedFilter={updatedFilter}
                        completionModal={completionModal}
                        setCompletionModel={setCompletionModel}
                        setAssessmentView={setAssessmentView}
                        setFiler={setFiler}
                        setUpdatedFilter={setUpdatedFilter}
                        filter={filter}
                        totalQuestions={questionsBoard.totalQuestions}
                        attemptedQuestions={questionsBoard.attemptedQuestions}
                        remainingQuestions={questionsBoard.remainingQuestions}
                        activeSnack={activeSnack}
                      />
                    ) : activeScreen === 1 ? (
                      <ReviewScreen
                        allQuestions={allQuestions}
                        course={course}
                        userId={userId}
                        addEditQuiz={addEditQuiz}
                        setActiveScreen={setActiveScreen}
                        userAttemptedQuestins={userAttemptedQuestins}
                        setUserAttemptedQuestions={setUserAttemptedQuestions}
                        newSelection={newSelection}
                      />
                    ) : activeScreen === 2 ? (
                      <ResultScreen
                        allQuestions={allQuestions}
                        userAttemptedQuestins={userAttemptedQuestins}
                        setActiveScreen={setActiveScreen}
                        course={course}
                        newSelection={newSelection}
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </GridComp>
            </GridComp>
          </Box>
          <MobileBottomBar
            selectionText='Click here to get started '
            openMobileDropDown={openMobileBar}
            handleOpenMobileDropDown={handleOpenMobileBar}
            showData={hasAssessment}
            selectedData={selectedData}
            leftSideMenu={
              <LeftSide
                onListClick={(e, i) => {
                  listItemClickHandler(e, i);
                }}
                setStructuredData={setStructuredData}
                hierarchyData={updatedHierarchy}
                isLibrary={false}
                isFlashcard={false}
                handleOpenMobileDropDown={handleOpenMobileBar}
                isSimulation={false}
                active={activeItem}
                isAssesment={true}
                selectedData={selectedData}
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                setHasAssessment={setHasAssessment}
                setSelectedData={setSelectedData}
                tab={'assessment'}
                setinitialData={setinitialData}
                updatedData={updateData}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default Assessment;
