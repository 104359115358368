import React from 'react';

const QuestionTwo = () => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55944 0.360352C3.5589 0.360352 3.5583 0.360352 3.55776 0.360352C3.3686 0.360352 3.19069 0.434089 3.05634 0.568096C2.92052 0.703585 2.8457 0.88399 2.8457 1.07604V11.8077C2.8457 12.2013 3.16712 12.5222 3.56227 12.5232C5.22814 12.5272 8.01912 12.8744 9.94452 14.8893V3.65828C9.94452 3.52487 9.91044 3.39955 9.84613 3.29585C8.26586 0.750925 5.22908 0.364257 3.55944 0.360352Z"
        fill="url(#paint0_linear_85_5163)"
      />
      <path
        d="M18.1544 11.8078V1.07604C18.1544 0.88399 18.0796 0.703585 17.9438 0.568096C17.8094 0.434089 17.6314 0.360352 17.4424 0.360352C17.4418 0.360352 17.4412 0.360352 17.4407 0.360352C15.7711 0.364325 12.7343 0.750993 11.154 3.29592C11.0897 3.39962 11.0557 3.52494 11.0557 3.65834V14.8893C12.9811 12.8744 15.772 12.5272 17.4379 12.5232C17.833 12.5222 18.1544 12.2013 18.1544 11.8078Z"
        fill="url(#paint1_linear_85_5163)"
      />
      <path
        d="M19.7844 2.83496H19.2655V11.8074C19.2655 12.8122 18.4468 13.6316 17.4405 13.6341C16.0275 13.6374 13.6976 13.9137 12.0476 15.4754C14.9014 14.7767 17.9097 15.2309 19.6242 15.6216C19.8383 15.6704 20.0595 15.62 20.231 15.4833C20.4019 15.3469 20.5 15.1431 20.5 14.9243V3.55052C20.5 3.15597 20.1789 2.83496 19.7844 2.83496Z"
        fill="url(#paint2_linear_85_5163)"
      />
      <path
        d="M1.73448 11.8074V2.83496H1.21556C0.821079 2.83496 0.5 3.15597 0.5 3.55052V14.9241C0.5 15.143 0.598047 15.3467 0.768957 15.4831C0.940338 15.6197 1.16135 15.6703 1.37576 15.6214C3.09024 15.2306 6.09867 14.7765 8.95228 15.4752C7.30231 13.9136 4.97247 13.6374 3.55947 13.634C2.55321 13.6316 1.73448 12.8122 1.73448 11.8074Z"
        fill="url(#paint3_linear_85_5163)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_85_5163"
          x1="6.39511"
          y1="0.360352"
          x2="6.39511"
          y2="14.8893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_85_5163"
          x1="14.605"
          y1="0.360352"
          x2="14.605"
          y2="14.8893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_85_5163"
          x1="16.2738"
          y1="2.83496"
          x2="16.2738"
          y2="15.6398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_85_5163"
          x1="4.72614"
          y1="2.83496"
          x2="4.72614"
          y2="15.6396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionTwo;
