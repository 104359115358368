import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import TypographyCompo from './../../../../../../../../UI/Typography/TypographyCompo';
import palette from './../../../../../../../../../config/palette.js';

export const DeleteWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 7, 2, 7),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#ffff',
}));
export const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  background: '#f9e5f5',
  padding: theme.spacing(2.5),
}));

export const NoteIconButton = styled(IconButton)(({ theme }) => ({
  background: '#f9e5f5',
  padding: theme.spacing(2, 2.5, 2, 2.5),
}));

export const CancelDeleteWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',
}));
export const Typorgraphy = styled(TypographyCompo)(({ theme, background }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ButtonTypo = styled(Button)(
  ({ theme, color1, textTransform }) => ({
    borderRadius: '15px',
    color: color1,
    marginRight: theme.spacing(3),
    textTransform: textTransform,
  })
);
export const ProgressBarTyporgraphy = styled(TypographyCompo)(({ theme }) => ({
  color: palette.colors.secondaryModified,
  marginTop: '-8px',
}));
