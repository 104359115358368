import React, { useState, useEffect } from 'react';
import {
  ListSubheader,
  ListItemText,
  Divider,
  Collapse,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
import defaultProps from './defaultProps.js';
import { StyledItemMobile, StyledListContainerMobile } from './List.style';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { notesDropdownActions } from '../../../redux/reducers/notesDropdown.js';
import AccordianMobileSvg from './icons/AccordianMobileSvg.jsx';
import MobileDetector from '../../../helpers/mobileDetector.jsx';
import MobileListIcon from '../RightBarAccordian/icons/mobileListicon.jsx';
import { Mixpanel } from '../../../helpers/Mixpanel.js';

const ListItemComp = ({
  structure,
  setStructure,
  item,
  nested,
  name,
  label,
  icon,
  childrenElements,
  listItemClickEvent,
  secondary,
  layer,
  type,
  expandAll,
  isFlashcard,
  isLibrary,
  active,
  handleOpenMobileDropDown,
  isAssesment,
  selection,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  completionPercentage,
  isNotes,
  ...props
}) => {
  const dispatch = useDispatch();
  const IsMobile = MobileDetector();
  const [selected, setSelected] = useState(null);
  const forceOpenState = (state) => {};
  const [padding, setPadding] = React.useState('0');
  const [selectedItem, setSelectedItem] = useState();
  const [openState, setOpenState] = useState(item.isExpanded);

  useEffect(() => {
    let previousData;

    if (selectedItem) {
      switch (item.type) {
        case 'unit':
          previousData = structure?.filter((unit) => {
            return unit.id === item.id;
          });
          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;

        case 'chapter':
          // if unit level exists
          if (item.unitId) {
            previousData = structure
              ?.filter((unit) => {
                return unit.id === item.unitId;
              })[0]
              ?.childrenElements?.filter((element) => {
                return element.id === item.id;
              });
          } else {
            previousData = structure?.filter((chapter) => {
              return chapter.id === item.id;
            });
          }
          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;

        case 'snack':
          previousData = structure
            ?.filter((unit) => {
              return unit.id === item.unitId;
            })[0]
            ?.childrenElements?.filter((element) => {
              return element.id === item.chapterId;
            })[0]
            ?.childrenElements?.filter((snackElement) => {
              return snackElement.id === item.id;
            });

          setOpenState(
            previousData?.length > 0 ? previousData[0].isExpanded : undefined
          );
          break;
        default:
          console.log('Unknown item type');
      }
    }
  }, [selectedItem, structure]);
  React.useEffect(() => {
    forceOpenState(expandAll);
  }, [expandAll]);

  React.useEffect(() => {
    switch (layer) {
      case 1: {
        return setPadding('15.16px');
      }
      case 2: {
        return setPadding('24px');
      }
      case 3: {
        return setPadding('40px');
      }
      default: {
        return setPadding('0');
      }
    }
  }, [layer]);

  const handleExpansion = (item) => {
    switch (item.type) {
      case 'unit':
        setStructure((prevData) => {
          let previousData = prevData.map((unit) => {
            if (unit.id === item.id) {
              return {
                ...unit,
                isExpanded: !unit.isExpanded,
                childrenElements: unit.childrenElements?.map((child) => ({
                  ...child,
                  isExpanded: false,
                })),
              };
            }
            return {
              ...unit,
              isExpanded: false,
              childrenElements: unit.childrenElements?.map((child) => ({
                ...child,
                isExpanded: false,
              })),
            };
          });
          return previousData;
        });

        break;

      case 'chapter':
        if (item.unitId) {
          setStructure((prevData) => {
            let previousData = prevData.map((unit) => {
              if (unit.id === item.unitId) {
                const modifiedChildren = unit.childrenElements.map(
                  (element) => {
                    if (element.id === item.id) {
                      return {
                        ...element,
                        isExpanded: !element.isExpanded,
                        childrenElements: element.childrenElements?.map(
                          (child) => ({
                            ...child,
                            isExpanded: false,
                          })
                        ),
                      };
                    }
                    return {
                      ...element,
                      isExpanded: false,
                      childrenElements: element.childrenElements?.map(
                        (child) => ({ ...child, isExpanded: false })
                      ),
                    };
                  }
                );
                return { ...unit, childrenElements: modifiedChildren };
              }
              return unit;
            });
            return previousData;
          });
        } else {
          setStructure((prevData) => {
            let previousData = prevData.map((chapter) => {
              if (chapter.id === item.id) {
                return { ...chapter, isExpanded: !chapter.isExpanded };
              }
              return {
                ...chapter,
                isExpanded: false,
                childrenElements: chapter.childrenElements?.map((child) => ({
                  ...child,
                  isExpanded: false,
                })),
              };
            });
            return previousData;
          });
        }
        break;

      case 'snack':
        if (item.unitId) {
          setStructure((prevData) => {
            let previousData = prevData.map((unit) => {
              if (unit.id === item.unitId) {
                let modifiedUnitsChildren = unit.childrenElements.map(
                  (element) => {
                    if (element.id === item.chapterId) {
                      let modifiedSnacksChildren = element.childrenElements.map(
                        (snackElements) => {
                          if (snackElements.id === item.id) {
                            return {
                              ...snackElements,
                              isExpanded: !snackElements.isExpanded,
                            };
                          }
                          return { ...snackElements, isExpanded: false };
                        }
                      );
                      return {
                        ...element,
                        childrenElements: modifiedSnacksChildren,
                      };
                    }
                    return { ...element, isExpanded: false };
                  }
                );
                return { ...unit, childrenElements: modifiedUnitsChildren };
              }

              return unit;
            });
            return previousData;
          });
        } else {
          setStructure((prevData) => {
            let previousData = prevData.map((chapter) => {
              let modifiedUnitsChildren = chapter.childrenElements.map(
                (element) => {
                  if (element.id === item.chapterId) {
                    let modifiedSnacksChildren = element.childrenElements.map(
                      (snackElements) => {
                        if (snackElements.id === item.id) {
                          return {
                            ...snackElements,
                            isExpanded: !snackElements.isExpanded,
                          };
                        }
                        return { ...snackElements, isExpanded: false };
                      }
                    );
                    return {
                      ...element,
                      childrenElements: modifiedSnacksChildren,
                    };
                  }
                  if (element.id === item.id) {
                    return { ...element, isExpanded: !element.isExpanded };
                  }
                  return { ...element, isExpanded: false };
                }
              );
              return { ...chapter, childrenElements: modifiedUnitsChildren };
            });
            return previousData;
          });
        }
        break;
      default:
        return null;
    }
  };

  const handleItemClick = () => {
    setSelected({
      label: label,
      layer: layer,
    });

    setSelectedItem(item);

    if (isNotes) {
      Mixpanel.track(
        `Notes : ${name} | Type : ${type} | | Layer : ${layer} | Action : Clicked`,
        {
          button_name: `${name} Card`,
          page: 'Notes',
        }
      );
    }

    if (IsMobile) {
      handleOpenMobileDropDown();
    }

    let isExpanded = !item.isExpanded;

    handleExpansion(item);

    listItemClickEvent({
      ...props,
      name,
      type,
      isExpanded,
      label,
      nested,
      layer,
    });
    if (isLibrary) {
      dispatch(
        notesDropdownActions.setMyLibraryData({
          layer,
          name,
          type,
          isExpanded,
          label,
          nested,
          id: props.id,
          open: isExpanded,
        })
      );
    } else {
      dispatch(
        notesDropdownActions.setDataforOpen({
          layer,
          name,
          type,
          isExpanded,
          label,
          nested,
          id: props.id,
          setOpen: isExpanded,
          open: isExpanded,
        })
      );
    }
  };

  return (
    <>
      {/* Mobile */}

      <StyledItemMobile
        onClick={(e) => handleItemClick()}
        layer={layer}
        padding={padding}
        active={item.isExpanded}
        selected={item.isExpanded}
        open={item.isExpanded}
      >
        <Stack
          direction='row'
          alignItems={'center'}
          gap={nested ? 2 : 0}
          marginLeft={'15px'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {nested ? (
              <AccordianMobileSvg
                // click={(e) => {
                //   setOpen((prevState) => !prevState);
                // }}
                open={nested && item.isExpanded}
              />
            ) : (
              <MobileListIcon isactive={!item.isExpanded} />
            )}

            <ListItemText
              sx={{ cursor: 'pointer' }}
              secondary={secondary ? secondary : null}
              primaryTypographyProps={{
                style: {
                  fontFamily: 'Inter',
                  overflow: 'hidden',
                  color: '#11263c',
                  fontFeatureSettings: " 'clig' off 'liga' off",
                  fontSize: '14px',
                },
              }}
            >
              {label ? label : name}
            </ListItemText>
          </div>

          {(isAssesment || isNotes || isFlashcard) && (
            <div>
              {type === 'snack' && isNotes ? (
                <Box style={{ position: 'relative', paddingTop: '5px' }}>
                  {completionPercentage ? (
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <ellipse cx='10' cy='10' rx='10' ry='10' fill='#e015a2' />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.36861 12.0901C8.49023 11.9514 8.68767 11.5685 8.79896 11.4483C9.17906 11.0386 9.52418 10.604 9.87094 10.1662C10.9047 8.85987 12.0219 7.61932 13.0277 6.29425C13.2097 6.05431 13.4637 5.74323 13.745 5.57138C13.9185 5.46526 14.103 5.40891 14.2899 5.42441C14.4777 5.43991 14.6735 5.5256 14.8632 5.73056C15.1179 6.00571 15.0773 6.39098 14.9695 6.7145C14.7467 7.38267 14.3124 7.98252 13.9081 8.5502C12.7791 10.1354 11.6148 11.6952 10.3626 13.1849C10.1146 13.48 9.84276 13.8559 9.52253 14.1491C9.16051 14.4804 8.73791 14.7065 8.23221 14.6616C7.81407 14.6245 7.47952 14.3773 7.16516 14.1221C6.3911 13.4936 5.61376 12.504 5.17074 11.6103C5.05594 11.3788 4.88244 10.8933 4.90897 10.5106C4.93221 10.1746 5.08763 9.90767 5.45576 9.82855C5.66283 9.78395 5.87272 9.83325 6.07111 9.93796C6.30799 10.0629 6.52821 10.2697 6.69631 10.4138C7.04707 10.7155 7.32081 11.0642 7.60912 11.3936C7.8345 11.6513 8.06881 11.8976 8.36861 12.0901ZM8.59141 12.2371C8.63156 12.285 8.65199 12.3482 8.64494 12.4127C8.6541 12.327 8.62381 12.2726 8.59141 12.2371Z'
                        fill='white'
                      />
                    </svg>
                  ) : (
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 19 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle
                        opacity='0.1'
                        cx='9.5'
                        cy='9.5'
                        r='9.5'
                        fill='#E015A2'
                      />
                    </svg>
                  )}
                </Box>
              ) : completionPercentage === 100 ? (
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <ellipse cx='10' cy='10' rx='10' ry='10' fill='#e015a2' />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M8.36861 12.0901C8.49023 11.9514 8.68767 11.5685 8.79896 11.4483C9.17906 11.0386 9.52418 10.604 9.87094 10.1662C10.9047 8.85987 12.0219 7.61932 13.0277 6.29425C13.2097 6.05431 13.4637 5.74323 13.745 5.57138C13.9185 5.46526 14.103 5.40891 14.2899 5.42441C14.4777 5.43991 14.6735 5.5256 14.8632 5.73056C15.1179 6.00571 15.0773 6.39098 14.9695 6.7145C14.7467 7.38267 14.3124 7.98252 13.9081 8.5502C12.7791 10.1354 11.6148 11.6952 10.3626 13.1849C10.1146 13.48 9.84276 13.8559 9.52253 14.1491C9.16051 14.4804 8.73791 14.7065 8.23221 14.6616C7.81407 14.6245 7.47952 14.3773 7.16516 14.1221C6.3911 13.4936 5.61376 12.504 5.17074 11.6103C5.05594 11.3788 4.88244 10.8933 4.90897 10.5106C4.93221 10.1746 5.08763 9.90767 5.45576 9.82855C5.66283 9.78395 5.87272 9.83325 6.07111 9.93796C6.30799 10.0629 6.52821 10.2697 6.69631 10.4138C7.04707 10.7155 7.32081 11.0642 7.60912 11.3936C7.8345 11.6513 8.06881 11.8976 8.36861 12.0901ZM8.59141 12.2371C8.63156 12.285 8.65199 12.3482 8.64494 12.4127C8.6541 12.327 8.62381 12.2726 8.59141 12.2371Z'
                    fill='white'
                  />
                </svg>
              ) : (
                <Box style={{ position: 'relative', paddingTop: '5px' }}>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 19 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      opacity='0.1'
                      cx='9.5'
                      cy='9.5'
                      r='9.5'
                      fill='#E015A2'
                    />
                  </svg>

                  <CircularProgress
                    sx={{ width: 20, height: 20 }}
                    style={{
                      color: '#e015a2',
                      width: '20px',
                      height: '20px',
                      position: 'absolute',
                      left: '0px',
                    }}
                    value={completionPercentage}
                    variant='determinate'
                  />
                </Box>
              )}
              {/* {type === 'snack' ? (
                <Box style={{ position: 'relative', paddingTop: '5px' }}>
                  {completionPercentage ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse cx="10" cy="10" rx="10" ry="10" fill="#e015a2" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.36861 12.0901C8.49023 11.9514 8.68767 11.5685 8.79896 11.4483C9.17906 11.0386 9.52418 10.604 9.87094 10.1662C10.9047 8.85987 12.0219 7.61932 13.0277 6.29425C13.2097 6.05431 13.4637 5.74323 13.745 5.57138C13.9185 5.46526 14.103 5.40891 14.2899 5.42441C14.4777 5.43991 14.6735 5.5256 14.8632 5.73056C15.1179 6.00571 15.0773 6.39098 14.9695 6.7145C14.7467 7.38267 14.3124 7.98252 13.9081 8.5502C12.7791 10.1354 11.6148 11.6952 10.3626 13.1849C10.1146 13.48 9.84276 13.8559 9.52253 14.1491C9.16051 14.4804 8.73791 14.7065 8.23221 14.6616C7.81407 14.6245 7.47952 14.3773 7.16516 14.1221C6.3911 13.4936 5.61376 12.504 5.17074 11.6103C5.05594 11.3788 4.88244 10.8933 4.90897 10.5106C4.93221 10.1746 5.08763 9.90767 5.45576 9.82855C5.66283 9.78395 5.87272 9.83325 6.07111 9.93796C6.30799 10.0629 6.52821 10.2697 6.69631 10.4138C7.04707 10.7155 7.32081 11.0642 7.60912 11.3936C7.8345 11.6513 8.06881 11.8976 8.36861 12.0901ZM8.59141 12.2371C8.63156 12.285 8.65199 12.3482 8.64494 12.4127C8.6541 12.327 8.62381 12.2726 8.59141 12.2371Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        opacity="0.1"
                        cx="9.5"
                        cy="9.5"
                        r="9.5"
                        fill="#E015A2"
                      />
                    </svg>
                  )}
                </Box>
              ) : completionPercentage === 100 ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="10" cy="10" rx="10" ry="10" fill="#e015a2" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.36861 12.0901C8.49023 11.9514 8.68767 11.5685 8.79896 11.4483C9.17906 11.0386 9.52418 10.604 9.87094 10.1662C10.9047 8.85987 12.0219 7.61932 13.0277 6.29425C13.2097 6.05431 13.4637 5.74323 13.745 5.57138C13.9185 5.46526 14.103 5.40891 14.2899 5.42441C14.4777 5.43991 14.6735 5.5256 14.8632 5.73056C15.1179 6.00571 15.0773 6.39098 14.9695 6.7145C14.7467 7.38267 14.3124 7.98252 13.9081 8.5502C12.7791 10.1354 11.6148 11.6952 10.3626 13.1849C10.1146 13.48 9.84276 13.8559 9.52253 14.1491C9.16051 14.4804 8.73791 14.7065 8.23221 14.6616C7.81407 14.6245 7.47952 14.3773 7.16516 14.1221C6.3911 13.4936 5.61376 12.504 5.17074 11.6103C5.05594 11.3788 4.88244 10.8933 4.90897 10.5106C4.93221 10.1746 5.08763 9.90767 5.45576 9.82855C5.66283 9.78395 5.87272 9.83325 6.07111 9.93796C6.30799 10.0629 6.52821 10.2697 6.69631 10.4138C7.04707 10.7155 7.32081 11.0642 7.60912 11.3936C7.8345 11.6513 8.06881 11.8976 8.36861 12.0901ZM8.59141 12.2371C8.63156 12.285 8.65199 12.3482 8.64494 12.4127C8.6541 12.327 8.62381 12.2726 8.59141 12.2371Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <Box style={{ position: 'relative', paddingTop: '5px' }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.1"
                      cx="9.5"
                      cy="9.5"
                      r="9.5"
                      fill="#E015A2"
                    />
                  </svg>

                  <CircularProgress
                    sx={{ width: 20, height: 20 }}
                    style={{
                      color: '#e015a2',
                      width: '20px',
                      height: '20px',
                      position: 'absolute',
                      left: '0px',
                    }}
                    value={completionPercentage}
                    variant="determinate"
                  />
                </Box>
              )} */}
            </div>
          )}
        </Stack>
      </StyledItemMobile>

      {nested && (
        <Collapse in={item.isExpanded} timeout='auto' unmountOnExit>
          <ListComp
            structure={structure}
            setStructure={setStructure}
            open={expandAll}
            onItemClick={listItemClickEvent}
            listSkeleton={childrenElements}
            isLibrary={isLibrary}
            active={item.isExpanded}
            handleOpenMobileDropDown={handleOpenMobileDropDown}
            isAssesment={isAssesment}
            selection={selection}
            selectedData={selectedData}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
            setSelectedData={setSelectedData}
            isNotes={isNotes}
            isFlashcard={isFlashcard}
            completionPercentage={completionPercentage}
          />
        </Collapse>
      )}
    </>
  );
};

const ListComp = ({
  structure,
  setStructure,
  hierarchyData,
  heading,
  listSkeleton,
  onItemClick,
  selection,
  open,
  setOpen,
  isFlashcard,
  isLibrary,
  active,
  handleOpenMobileDropDown,
  isAssesment,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  isNotes,
  completionPercentage,
}) => {
  const { data, myLibraryData } = useSelector((state) => state.notesDropdown);
  function isIdPresentRecursive(elements, idToCheck) {
    return elements?.some((element) => {
      if (element.id === idToCheck) {
        return true;
      }

      if (element.childrenElements) {
        return isIdPresentRecursive(element.childrenElements, idToCheck);
      }

      return false;
    });
  }

  return (
    <>
      <StyledListContainerMobile
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          heading !== '' && (
            <ListSubheader component='div' id='nested-list-subheader'>
              {heading}
            </ListSubheader>
          )
        }
      >
        {listSkeleton.map((item, index) => {
          let isIdPresent;

          if (isLibrary) {
            isIdPresent =
              item.id === myLibraryData.id ||
              isIdPresentRecursive(item.childrenElements, myLibraryData.id);
          } else {
            isIdPresent =
              item.id === data.id ||
              isIdPresentRecursive(item.childrenElements, data.id);
          }

          return (
            <React.Fragment key={index}>
              {item.name === 'divider' ? (
                <Divider />
              ) : (
                <ListItemComp
                  setStructure={setStructure}
                  structure={structure}
                  item={item}
                  openlist={isIdPresent}
                  selection={selection}
                  expandAll={open}
                  setopen={setOpen}
                  listItemClickEvent={onItemClick}
                  nested={item.childrenElements}
                  isFlashcard={isFlashcard}
                  isLibrary={isLibrary}
                  active={active}
                  handleOpenMobileDropDown={handleOpenMobileDropDown}
                  isAssesment={isAssesment}
                  selectedData={selectedData}
                  activeScreen={activeScreen}
                  setActiveScreen={setActiveScreen}
                  setSelectedData={setSelectedData}
                  isNotes={isNotes}
                  hierarchyData={hierarchyData}
                  completionPercentage={completionPercentage}
                  {...item}
                />
              )}
            </React.Fragment>
          );
        })}
      </StyledListContainerMobile>
    </>
  );
};

export default ListComp;

ListComp.propTypes = {
  heading: PropTypes.string,
  setStructure: PropTypes.func,
  listSkeleton: PropTypes.array,
  onItemClick: PropTypes.func,
};
ListComp.defaultProps = {
  ...defaultProps,
};
