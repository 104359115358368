import { baseURL } from './config/baseUrl';

const postLearningObjective = async ({ value, id }, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    completed: value,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  try {
    const response = await fetch(`${baseURL}/userLoObj/${id}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.text();
    const data = JSON.parse(result);
    return data;
  } catch (error) {
    console.error('Error:', error);
    return {};
  }
};

const saveTimeStatistics = async ({ startDate, endDate }, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    startDate,
    endDate,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/stats`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => console.error('error', error));
  return data;
};

const userAverageTime = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/average-time`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => console.error('error', error));
  return data;
};

const getUserAverageTime = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/user/get-average-time?from=${payload?.from}&to=${payload?.to}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => console.error('error', error));
  return data;
};

const buildFunc = (payload) => {
  try {
    return { data: payload, status: 'ok' };
  } catch (err) {
    return { data: null, status: 'error', error: err };
  }
};

const postFunc = async (payload) => {
  return;
};

const getGrades = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/grade-level`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const addNewUser = async (details) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    ...details,
    email: details.email?.toLowerCase(),
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const postFlashcardButtonStatus = async (
  token,
  chapterOrSnackId,
  response,
  flashcardId
) => {
  const raw = JSON.stringify({
    chapterId: chapterOrSnackId,
    response: response.response,
  });

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/flashcard/track-score/${response.id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const myLibraryFlashcardButtonStatus = async (token, response, flashcardId) => {
  const raw = JSON.stringify({
    response: response,
  });

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/flashcard/track-score/${flashcardId}?library=true`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const forgetPassAPI = async (email) => {
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  let raw = JSON.stringify({
    email,
  });

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/auth/forgot-password`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const resetPasswordApi = async (otpCode, password, confirmPass) => {
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  let raw = JSON.stringify({
    otpCode,
    password: password,
    c_password: confirmPass,
  });
  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = await fetch(`${baseURL}/auth/update-password`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const loginHandler = async (credentials) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const { email, password } = credentials;

  const raw = JSON.stringify({ email: email.toLowerCase(), password });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/login`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const verifyEmail = async (otp) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ userOTP: otp });

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/verify-email`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const send = async (question, newChat, token, userId, uniqueRequestId) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({ question, newChat });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/user/chat?userId=${userId}&requestId=${uniqueRequestId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const verifyOTP = async (otp) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ userOTP: otp });

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/auth/verify-otp`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const resendOTP = async (email) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ email });

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/resend-otp`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getCurrentUserData = async (recievedToken) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/me`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const uploadUserImage = async (userImage) => {
  let token = localStorage.getItem('auth');
  let URL = `${baseURL}/user/user-image`;
  const data = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(userImage),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const updateUserProfile = async (payload) => {
  let token = localStorage.getItem('auth');
  let URL = `${baseURL}/user`;
  const data = await fetch(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const getPublishedCourses = async (recievedToken) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/course/user/courses`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyWithObjectives = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=syllabus`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForNotes = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=notes`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForFlashCards = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=flashcards`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getAverageScores = async (recievedToken, courseIds, userId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/user/average-score?courseIds=${courseIds}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getCourseProgress = async (recievedToken, courseIds) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/user/course-progress?courseIds=${courseIds}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getUserStatistics = async (recievedToken) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/get-statistics`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForSimlations = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=simulations`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForAssessment = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=assessment`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const userReferrals = async (recievedToken) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/my-referrals`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForAnalytics = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/course/${courseId}?type=analytics`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getLibraryHierarchyForflashcards = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/heirarchy/userflashheirarchy?courseId=${courseId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getHierarchyForMyLibrary = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/heirarchy?courseId=${courseId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const getCourseFromDB = async (recievedToken, courseId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/course/${courseId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getAllTagsFromDB = async (recievedToken) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${recievedToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/tag`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const getAllConceptTagsFromDB = async (Token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${Token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/conceptTag`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

const fetchFlashcardsFromLibrary = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/my-library/flashcards`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const fetchNotesFromLibrary = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/my-library/notes`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getNotesFromDbLearningResources = async ({ id, type }, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/note/snack-id/${id}?resource=${type}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getFlashcards = async (id, type, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  let url = `${baseURL}/flashcard/${id}?resource=${type}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getSimulations = async (id, type, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  let url = `${baseURL}/simulation/list/${id}?resource=${type}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getQuestions = async (token, id, updatedFilter, type) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const { from, to } = updatedFilter;

  const queryParams = new URLSearchParams({ from, to });

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const url = `${baseURL}/quizQuestion/questions/${id}?${queryParams.toString()}&resource=${type}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => error);
  return data;
};

const getAllQuestionsData = async (token, payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId } = payload;

  let raw = {};

  if (Array.isArray(chapterId) && chapterId.length > 0) {
    raw.chapterId = chapterId;
  }

  if (Array.isArray(unitId) && unitId.length > 0) {
    raw.unitId = unitId;
  }

  if (courseId) raw.courseId = courseId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = unitId
    ? `${baseURL}/assessmentTracker/getAllQuestionsData/${payload?.userId}?courseId=${payload?.courseId}&&unitId=${payload?.unitId}`
    : `${baseURL}/assessmentTracker/getAllQuestionsData/${payload?.userId}?courseId=${payload?.courseId}&&chapterId=${payload?.chapterId}`;

  const data = await fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getUserAnalytics = async (token, id, type, updatedFilter) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const url = `${baseURL}/userQuiz/userResult/${id}?resource=${type}&from=${updatedFilter?.from}&to=${updatedFilter?.to}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => error);
  return data;
};

const deleteUserAnalytics = async (token, id) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const url = `${baseURL}/userQuiz/delete/${id}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => error);
  return data;
};

const getUserQuestions = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const url = `${baseURL}/userQuiz/attemptedQuestions`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => error);
  return data;
};

const addEditUserQuiz = async (token, payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/userQuiz`, requestOptions)
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const getUserAnalyticsQuestions = async (token, id, type, updatedFilter) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const url = `${baseURL}/userQuiz/getUserAnalyticsQuestions/${id}?resource=${type}&from=${updatedFilter?.from}&to=${updatedFilter?.to}`;

  const data = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => error);
  return data;
};

const addNoteToLibrary = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const { chapterId, tagId, snackId } = payload;

  let raw = {};

  if (chapterId) raw.chapterId = chapterId;
  if (tagId) raw.tagId = tagId;
  if (snackId) raw.snackId = snackId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/my-library/move/notes`, requestOptions)
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const addCompletedNotes = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId, snackId } = payload;

  let raw = {};

  if (chapterId) raw.chapterId = chapterId;
  if (courseId) raw.courseId = courseId;
  if (snackId) raw.snackId = snackId;
  if (unitId) raw.unitId = unitId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/notesTracker/completedNotes/${payload.userId}`,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const getUserCompleteNotes = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId } = payload;

  let raw = {};

  if (Array.isArray(chapterId) && chapterId.length > 0) {
    raw.chapterId = chapterId;
  }

  if (Array.isArray(unitId) && unitId.length > 0) {
    raw.unitId = unitId;
  }

  if (courseId) raw.courseId = courseId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = unitId
    ? `${baseURL}/notesTracker/getCompletedNotes/${payload?.userId}?courseId=${payload?.courseId}&&unitId=${payload?.unitId}`
    : `${baseURL}/notesTracker/getCompletedNotes/${payload?.userId}?courseId=${payload?.courseId}&&chapterId=${payload?.chapterId}`;

  const data = await fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getCompletedAssessment = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId } = payload;

  let raw = {};

  if (Array.isArray(chapterId) && chapterId.length > 0) {
    raw.chapterId = chapterId;
  }

  if (Array.isArray(unitId) && unitId.length > 0) {
    raw.unitId = unitId;
  }

  if (courseId) raw.courseId = courseId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = unitId
    ? `${baseURL}/assessmentTracker/getCompletedAssessment/${payload?.userId}?courseId=${payload?.courseId}&&unitId=${payload?.unitId}`
    : `${baseURL}/assessmentTracker/getCompletedAssessment/${payload?.userId}?courseId=${payload?.courseId}&&chapterId=${payload?.chapterId}`;

  const data = await fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const userRecentActivity = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  let raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: raw,
  };

  const data = await fetch(`${baseURL}/user/recent-activity`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const oAuthSignUp = async (id_token, refCode) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: id_token,
      referralCode: refCode,
    }),
  };

  const data = await fetch(`${baseURL}/user/auth/google`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const oAuthSignIn = async (token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: token,
    }),
  };

  const data = await fetch(`${baseURL}/user/google/login`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getUserRecentActivity = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/activity`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const chatHistory = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/chat-history`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const userSingleChat = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/user/single-chat`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getUserCompleteFC = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId } = payload;

  let raw = {};

  if (Array.isArray(chapterId) && chapterId.length > 0) {
    raw.chapterId = chapterId;
  }

  if (Array.isArray(unitId) && unitId.length > 0) {
    raw.unitId = unitId;
  }

  if (courseId) raw.courseId = courseId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = unitId
    ? `${baseURL}/flashcardsTracker/getCompletedFlashcards/${payload?.userId}?courseId=${payload?.courseId}&&unitId=${payload?.unitId}`
    : `${baseURL}/flashcardsTracker/getCompletedFlashcards/${payload?.userId}?courseId=${payload?.courseId}&&chapterId=${payload?.chapterId}`;

  const data = await fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const getAllFC = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const { courseId, chapterId, unitId } = payload;

  let raw = {};

  if (Array.isArray(chapterId) && chapterId.length > 0) {
    raw.chapterId = chapterId;
  }

  if (Array.isArray(unitId) && unitId.length > 0) {
    raw.unitId = unitId;
  }

  if (courseId) raw.courseId = courseId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = unitId
    ? `${baseURL}/flashcardsTracker/getAllFlashcards/?courseId=${payload?.courseId}&&unitId=${payload?.unitId}`
    : `${baseURL}/flashcardsTracker/getAllFlashcards/?courseId=${payload?.courseId}&&chapterId=${payload?.chapterId}`;

  const data = await fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const deleteCompletedNotes = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/notesTracker/deleteCompletedNotes/${payload.userId}?snackId=${payload.snackId}`,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const addFlashcardToLibrary = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  let raw = {};

  if (payload.chapterId) raw.chapterId = payload.chapterId;
  if (payload.snackId) raw.snackId = payload.snackId;
  if (payload.tagId) raw.tagId = payload.tagId;

  raw = JSON.stringify(raw);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/move/flashcards`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getFlashcardsFromLibrary = async (token, type, id, tagId) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endPoint = tagId
    ? `${baseURL}/my-library/mylibflashcards/${id}/${type}?tagId=${tagId}`
    : `${baseURL}/my-library/mylibflashcards/${id}/${type}`;

  const data = await fetch(endPoint, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const updateNotesOnLibrary = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({
    body: payload.body,
  });

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/note/${payload.id}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const restNotesFromDB = async (token, ids, dataType) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const idsJson = JSON.stringify(ids);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: idsJson,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/note/reset/${ids.snackId}?resource=${dataType}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const deleteNote = async (noteId, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/note/${noteId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteFlashcardStack = async (token, fcStackId, isUnit, isChapter) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endPoint = isUnit
    ? `${baseURL}/my-library/flashcard/${fcStackId}?isUnit=true`
    : isChapter
    ? `${baseURL}/my-library/flashcard/${fcStackId}?isChapter=true`
    : `${baseURL}/my-library/flashcard/${fcStackId}?stack=true`;

  const data = await fetch(endPoint, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};
const deleteMyLibraryNotesData = async (token, id, dataType) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/my-library/notes/clear/?resource=${dataType}&resourceId=${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
  return data;
};

const createFlashcardStack = async (payload, token) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/my-library/fcstack`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const addHighlightedNotes = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/highlight`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const updateHighlightedNote = async (payload) => {
  let token = localStorage.getItem('auth');
  let URL = `${baseURL}/highlight/${payload.id}`;
  const data = await fetch(URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);

  return data;
};

// resource may be chapter,note,snack
const getHighlightedNotes = async (resourceId, resource) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/highlight/${resourceId}?resource=${resource}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const addComment = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/comment`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getComments = async (resourceId, resource, library) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/comment/${resourceId}?resource=${resource}&&library=${
      library ? library : false
    }`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const deleteComment = async (commentId, resource) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const data = await fetch(
    `${baseURL}/comment/${commentId}?resource=${resource}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const getLearningObjectiveByChapter = async (chapterId) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(`${baseURL}/lo/chapter/${chapterId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const noteByChapterandTag = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/tag/app/contentbychapter?chapterId=${payload.chapterId}&tagId=${payload.tagId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const myLibraryNoteByChapterandTag = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/my-library/contentbychapter?chapterId=${payload.chapterId}&tagId=${payload.tagId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const noteBySnackandTag = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/tag/app/contentbysnack?snackId=${payload.snackId}&tagId=${payload.tagId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const myLibraryNoteBySnackandTag = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/my-library/contentbysnack?snackId=${payload.snackId}&tagId=${payload.tagId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const tagsByUnitId = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/my-library/tagsbyunit?courseId=${payload.courseId}&unitId=${payload.unitId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const tagsByChapterId = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/my-library/tagsbychapter?courseId=${payload.courseId}&chapterId=${payload.chapterId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};
const tagsBySnackId = async (payload) => {
  let token = localStorage.getItem('auth');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = await fetch(
    `${baseURL}/my-library/tagsbysnack?courseId=${payload.courseId}&snackId=${payload.snackId}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
  return data;
};

const addFeedback = async (token, payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/help/sendFeedback`, requestOptions)
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const reportBug = async (token, payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/help/reportBug`, requestOptions)
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const contactUs = async (token, payload) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const data = await fetch(`${baseURL}/help/contact`, requestOptions)
    .then((response) => response)
    .catch((error) => error);
  return data;
};

const api = {
  // Correct format

  getLearningObjectiveByChapter: (chapterId) =>
    getLearningObjectiveByChapter(chapterId),
  getUser: (token) => getCurrentUserData(token),

  getHighlightedNotes: (id, resource) => getHighlightedNotes(id, resource),
  getComments: (id, resource, library) => getComments(id, resource, library),
  getCourses: (token) => getPublishedCourses(token),
  // getListData: () => buildFunc(listData),
  getDashboardStatisticsSummary: () => buildFunc(),
  // Charts data needs improvement
  getChartsData: () => buildFunc(),
  getUserEmail: () => buildFunc(),
  getAllTags: (token, isSimulation) => getAllTagsFromDB(token, isSimulation),
  getAllConceptTags: (token) => getAllConceptTagsFromDB(token),
  getNotesFromDbLearningResources: (id, token) =>
    getNotesFromDbLearningResources(id, token),
  // Note: for getSyllabus API, please look at syllabus.json if a course has units and syllabusWithoutUnits.json if the course does not have units. It is located in the mockdata folder and is also commented on line 10
  getSyllabus: (token, courseId) => getCourseFromDB(token, courseId),
  getHierarchyWithObjectives: (token, courseId) =>
    getHierarchyWithObjectives(token, courseId),
  getHierarchyForNotes: (token, courseId) =>
    getHierarchyForNotes(token, courseId),
  getHierarchyForFlashCards: (token, courseId) =>
    getHierarchyForFlashCards(token, courseId),
  getHierarchyForSimlations: (token, courseId) =>
    getHierarchyForSimlations(token, courseId),
  getAverageScores: (token, courseId) => getAverageScores(token, courseId),
  getCourseProgress: (token, courseId) => getCourseProgress(token, courseId),
  getUserStatistics: (token) => getUserStatistics(token),
  getHierarchyForAssessment: (token, courseId) =>
    getHierarchyForAssessment(token, courseId),
  getHierarchyForAnalytics: (token, courseId) =>
    getHierarchyForAnalytics(token, courseId),

  getHierarchyForMyLibrary: (token, courseId) =>
    getHierarchyForMyLibrary(token, courseId),
  getLibraryHierarchyForflashcards: (token, courseId) =>
    getLibraryHierarchyForflashcards(token, courseId),
  // POST requests
  postTag: (payload) => postFunc(payload),

  // ADDING APIS FROM BACKEND
  // GET
  getGrades: () => getGrades(),
  // POST
  addHighlightedNotes: (payload) => addHighlightedNotes(payload),
  addComment: (payload) => addComment(payload),
  addNewUser: (userDetails) => addNewUser(userDetails),
  forgetPasswordSendEmail: (email) => forgetPassAPI(email),
  resetPassowrd: (otpCode, password, confirmPass) =>
    resetPasswordApi(otpCode, password, confirmPass),
  login: (credentials) => loginHandler(credentials),
  verifyEmail: (otp) => verifyEmail(otp),
  verifyOTP: (otp) => verifyOTP(otp),
  send: (question, newChat, token, userId, uniqueRequestId) =>
    send(question, newChat, token, userId, uniqueRequestId),
  resendOTP: (email) => resendOTP(email),
  updateHighlightedNote: (payload) => updateHighlightedNote(payload),
  noteByChapterandTag: (payload) => noteByChapterandTag(payload),
  myLibraryNoteByChapterandTag: (payload) =>
    myLibraryNoteByChapterandTag(payload),
  noteBySnackandTag: (payload) => noteBySnackandTag(payload),
  myLibraryNoteBySnackandTag: (payload) => myLibraryNoteBySnackandTag(payload),
  uploadUserImage: (userImage) => uploadUserImage(userImage),
  updateUserProfile: (payload) => updateUserProfile(payload),
  getCurrentUserData: (token) => getCurrentUserData(token),
  getFlashcards: (id, type, token) => getFlashcards(id, type, token),
  getSimulations: (id, type, token) => getSimulations(id, type, token),
  addNoteToLibrary: (payload, token) => addNoteToLibrary(payload, token),
  addCompletedNotes: (payload, token) => addCompletedNotes(payload, token),
  getUserCompleteNotes: (payload, token) =>
    getUserCompleteNotes(payload, token),
  getCompletedAssessment: (payload, token) =>
    getCompletedAssessment(payload, token),
  userRecentActivity: (payload, token) => userRecentActivity(payload, token),
  getUserRecentActivity: (token) => getUserRecentActivity(token),
  oAuthSignUp: (id_token, refCode) => oAuthSignUp(id_token, refCode),
  oAuthSignIn: (token) => oAuthSignIn(token),
  chatHistory: (token) => chatHistory(token),
  userSingleChat: (token) => userSingleChat(token),
  getUserCompleteFC: (payload, token) => getUserCompleteFC(payload, token),
  getAllFC: (payload, token) => getAllFC(payload, token),
  deleteCompletedNotes: (payload, token) =>
    deleteCompletedNotes(payload, token),
  fetchNotesFromLibrary: (token) => fetchNotesFromLibrary(token),
  getFlashcardsFromLibrary: (token, type, id, tagId) =>
    getFlashcardsFromLibrary(token, type, id, tagId),
  getQuestions: (token, id, updatedFilter, type) =>
    getQuestions(token, id, updatedFilter, type),
  getAllQuestionsData: (token, payload) => getAllQuestionsData(token, payload),
  getUserAnalytics: (token, id, type, updatedFilter) =>
    getUserAnalytics(token, id, type, updatedFilter),
  deleteUserAnalytics: (token, id) => deleteUserAnalytics(token, id),
  getUserQuestions: (token) => getUserQuestions(token),
  addEditUserQuiz: (token, payload) => addEditUserQuiz(token, payload),
  addFlashcardToLibrary: (payload, token) =>
    addFlashcardToLibrary(payload, token),
  fetchFlashcardsFromLibrary: (token) => fetchFlashcardsFromLibrary(token),
  updateNotesOnLibrary: (payload, token) =>
    updateNotesOnLibrary(payload, token),
  restNotesFromDB: (token, data, dataType) =>
    restNotesFromDB(token, data, dataType),
  deleteNote: (noteId, token) => deleteNote(noteId, token),
  createFlashcardStack: (payload, token) =>
    createFlashcardStack(payload, token),
  deleteComment: (noteId, resource) => deleteComment(noteId, resource),
  deleteMyLibraryNotes: (token, id, dataType) =>
    deleteMyLibraryNotesData(token, id, dataType),
  userReferrals: (token) => userReferrals(token),
  postFlashcardButtonStatus: (token, chapterOrSnackId, response, flashcardId) =>
    postFlashcardButtonStatus(token, chapterOrSnackId, response, flashcardId),
  myLibraryFlashcardButtonStatus: (token, response, flashcard) =>
    myLibraryFlashcardButtonStatus(token, response, flashcard),
  saveTimeStatistics: (payload, token) => saveTimeStatistics(payload, token),
  userAverageTime: (payload, token) => userAverageTime(payload, token),
  getUserAverageTime: (payload, token) => getUserAverageTime(payload, token),
  postLearningObjective: (payload, token) =>
    postLearningObjective(payload, token),
  getUserAnalyticsQuestions: (token, id, type, updatedFilter) =>
    getUserAnalyticsQuestions(token, id, type, updatedFilter),
  deleteFlashcardStack: (token, fcStackId, isUnit, isChapter) =>
    deleteFlashcardStack(token, fcStackId, isUnit, isChapter),
  tagsBySnackId: (payload) => tagsBySnackId(payload),
  tagsByChapterId: (payload) => tagsByChapterId(payload),
  tagsByUnitId: (payload) => tagsByUnitId(payload),
  addFeedback: (token, payload) => addFeedback(token, payload),
  reportBug: (token, payload) => reportBug(token, payload),
  contactUs: (token, payload) => contactUs(token, payload),
};

export default api;
