import { styled } from '@mui/system';
import Box from '../../Box/Box.jsx';
import Tab from '@mui/material/Tab';
import BoxComp from './../../Box/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  typography: 'body1',
  margin: 0,
}));

export const AntTab = styled(Tab)(
  ({ theme, selectedtabcolor, active, isTooltip }) => ({
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: '14px',
    padding: '0px 20px !important',
    background: active && '#FEF1FB',
    color: isTooltip ? '#e015a2' : 'rgba(17, 38, 60, 0.70)',
    fontWeight: '400',
    '&.Mui-selected': {
      color: selectedtabcolor,
    },
  })
);

export const BoxWrapper = styled(BoxComp)(({ theme, selectedtabcolor }) => ({
  background: 'white',
  boxShadow: '0px 9px 40px 4px rgba(173,180,197,0.45)',
  webkitBoxShadow: '0px 9px 40px 4px rgba(173,180,197,0.45)',
  mozBoxShadow: '0px 9px 40px 4px rgba(173,180,197,0.45)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) !important ',
  background: 'rgba(255, 255, 255, 0.80)',
  backdropFilter: 'blur(6.5px)',
}));

export const Step = styled('span')(() => ({
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Inter',
}));
