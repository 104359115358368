import React, { useEffect, useRef, useState, memo } from 'react';
import io from 'socket.io-client';
import api from '../../../Services';
import { useSnackbar } from 'notistack';
import send from '../../../assets/images/user/Send.svg';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import Logo from '../../../../src/assets/images/assessment/Octopus.svg';
import BotSvg from '../../../assets/images/user/Bot.svg';
import {
  Wrapper,
  Heading,
  InputWrapper,
  Question,
  Answer,
  TypingContainer,
  Input,
  Button,
  GeneratedQuestionsWrapper,
  BoldText,
  LogoWrapper,
  SendIcon,
  QuestionContainer,
  InputContainer,
  AnswerContainer,
  BotIcon,
  Container,
  HistoryWrapper,
  Banner,
  BannerHeading,
  BannerDetails,
  ContentWrapper,
  QuestionImageWrapper,
  ProfileImg,
  UserName,
  LogoImg,
  TypingWrapper,
  SendWrapper,
  BannerContent,
  BannerIcon,
  BannerIconWrapper,
  LoaderWrapper,
  HeadingWrapper,
  ResetChatWrapper,
  ExpandChatWrapper,
  StopIcon,
} from './aiChatbot.style';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import Loader from '../../UI/Loader/Loader';
import ToolTip from '../../UI/Tooltip/ToolTip';
import QuestionOne from '../../../assets/images/chat/QuestionOne';
import QuestionTwo from '../../../assets/images/chat/QuestionTwo';
import QuestionThree from '../../../assets/images/chat/QuestionThree';
import QuestionFour from '../../../assets/images/chat/QuestionFour';
import QuestionFive from '../../../assets/images/chat/QuestionFive';
import QuestionSix from '../../../assets/images/chat/QuestionSix';
import ChatIcon from '../../../assets/images/chat/Chat_Icon.png';
import SendMessage from '../../../assets/images/chat/Send.png';
import TinyOctopus from '../../../assets/images/chat/Tiny_Octopus.png';
import defaultImg from '../../../assets/images/user/default.webp';
import Restart from '../../../assets/images/chat/Restart.png';
import Add from '../../../assets/images/chat/Add.png';
import Waves from '../../../assets/images/user/Waves.gif';
import Expand from '../../../assets/images/chat/Expand.png';
import Close from '../../../assets/images/chat/Close.png';

const generatedQuestions = [
  {
    title: 'Explain a concept',
    image: <QuestionOne />,
  },
  {
    title: '⁠Help me revise',
    image: <QuestionTwo />,
  },
  {
    title: '⁠Generate questions',
    image: <QuestionThree />,
  },
  {
    title: '⁠Create flashcards',
    image: <QuestionFive />,
  },
  // {
  //   title: '⁠Help with a misconception',
  //   image: <QuestionFour />,
  // },
  // {
  //   title: '⁠Summarize notes',
  //   image: <QuestionSix />,
  // },
];

const AiChatBot = ({
  isWidget = false,
  fullView = false,
  setFullView,
  HandleBotClick,
}) => {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [initialMessage, setInitialMessage] = useState(false);
  const [generateQ, setGenerateQ] = useState(null);
  const [typing, setTyping] = useState(false);
  const [enable, setEnable] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [showName, setShowName] = useState(null);
  const [response, setResponse] = useState('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [history, setHistory] = useState([]);
  const [streaming, setStreaming] = useState(false);
  const ref = useRef();
  const textareaRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const socket = useRef(null);

  const user = useSelector((state) => state.user);

  const debouncedMathJaxUpdate = useRef(
    debounce(() => {
      try {
        if (window.MathJax && window.MathJax.Hub) {
          window.MathJax.Hub.Typeset();
        } else {
          console.warn('MathJax is not available.');
        }
      } catch (error) {
        console.error('Error in MathJax update:', error);
      }
    }, 300)
  ).current;

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ['websocket'],
    });
    socket.current.on('connect', () => {
      console.log('Socket connected');
    });

    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.current.on('connect_error', (err) => {
      console.log('err.message: ', err.message);
      console.log('err.description: ', err.description);
      console.log('err.context: ', err.context);
    });

    const appendMessage = (data) => {
      let filteredText = data.text.replace(
        /assistant\s*>?\s*file_search.*?(\s*$)/gim,
        ''
      );

      filteredText = filteredText.replace(/【\d+:\d+†[^】]+】/g, '');
      filteredText = filteredText.replace(/^###\s*/, '');
      if (!isCancelled) {
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage && lastMessage.type === 'answer') {
            return prevMessages.map((msg, index) =>
              index === prevMessages.length - 1
                ? { ...msg, data: msg.data + filteredText }
                : msg
            );
          } else {
            return [...prevMessages, { type: 'answer', data: filteredText }]; // Add new filtered message
          }
        });
      }

      setTyping(false);
    };

    socket.current.on('textEnd', () => {
      setStreaming(false);
    });

    socket.current.on('textCreated', appendMessage);
    socket.current.on('textDelta', appendMessage);
    socket.current.on('toolCallCreated', appendMessage);
    socket.current.on('codeInterpreterInput', appendMessage);
    socket.current.on('codeInterpreterOutput', appendMessage);
    socket.current.on('codeInterpreterLogs', appendMessage);

    if (!isWidget) {
      getSingleChat();
    }

    return () => {
      socket.current.disconnect();
    };
  }, []);

  const handleCancel = () => {
    setIsCancelled(true);
    setTyping(false);
    socket.current.emit('cancelRequest', { requestId });
    setStreaming(false);
  };

  const sendQuestion = async () => {
    if (question.trim().length > 0 && isWidget && initialMessage) {
      setInitialMessage(false);
    }

    if (question.trim().length > 0 && enable) {
      setTyping(true);
      setEnable(false);
      setStreaming(true);
      const token = localStorage.getItem('auth');

      setMessages((prev) => [
        ...prev,
        {
          type: 'question',
          data: question.trim(),
        },
      ]);

      setQuestion('');

      const userId = user?.id;
      const uniqueRequestId = `req_${Date.now()}`;
      setRequestId(uniqueRequestId);

      try {
        const response = await api.send(
          question,
          newChat,
          token,
          userId,
          uniqueRequestId
        );
        if (response?.error) {
          enqueueSnackbar('Some error occurred. Please try again', {
            variant: 'error',
            autoHideDuration: 4000,
          });
          setTyping(false);
          setNewChat(false);
          setStreaming(false);

          return;
        }
        // setTyping(false);
        setNewChat(false);
        setGenerateQ(null);
        setEnable(true);
      } catch (error) {
        console.error('API error:', error);
        enqueueSnackbar('Some error occurred. Please try again', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setTyping(false);
      }

      socket.current.emit('newQuestion', { question });
    }
  };

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        const wrapperHeight = wrapperRef.current.scrollHeight;
        if (wrapperHeight > 600 && messages.length > 1) {
          scrollToBottom();
        }
      }, 0);
    }
  }, [messages]);

  useEffect(() => {
    if (isWidget) {
      // setMessages((prev) => [
      //   { type: 'question', data: 'Hi. How can I help you?' },
      // ]);

      setInitialMessage(true);
    }
  }, [isWidget]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 24
      }px`;
    }
  }, [question]);

  useEffect(() => {
    const token = localStorage.getItem('auth');

    const generateQuestion = async () => {
      setTyping(true);
      setEnable(false);
      setStreaming(true);

      const userId = user?.id;
      const uniqueRequestId = `req_${Date.now()}`;
      setRequestId(uniqueRequestId);

      try {
        const userId = user?.id;
        const response = await api.send(
          generateQ,
          newChat,
          token,
          userId,
          uniqueRequestId
        );
        if (response?.error) {
          enqueueSnackbar('Some error occurred. Please try again', {
            variant: 'error',
            autoHideDuration: 4000,
          });
          setTyping(false);
          setNewChat(false);
          return;
        }
        // setTyping(false);
        setNewChat(false);
        setGenerateQ(null);
        setEnable(true);
      } catch (error) {
        console.error('API error:', error);
        enqueueSnackbar('Some error occurred. Please try again', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setTyping(false);
      }

      socket.current.emit('newQuestion', { question: generateQ });
    };

    if (generateQ !== null) {
      generateQuestion();
    }
  }, [generateQ]);

  const renderAnswer = (text) => {
    const formattedText = text.replace(/^\s*- /gm, '   • ');

    const parts = formattedText.split(/\*\*(.*?)\*\*/g);
    const renderedParts = parts.map((part, i) =>
      i % 2 === 1 ? (
        <BoldText key={`bold-${i}`}>{part}</BoldText>
      ) : (
        <span key={`text-${i}`} dangerouslySetInnerHTML={{ __html: part }} />
      )
    );

    debouncedMathJaxUpdate();

    return renderedParts;
  };

  const MemoizedMathJax = memo(({ children }) => <MathJax>{children}</MathJax>);

  // const getChatHistory = async () => {
  //   let token = localStorage.getItem('auth');

  //   // setLoading(true);

  //   await api
  //     .chatHistory(token)
  //     .then((res) => {
  //       // setHistory(res?.chat);
  //       // setLoading(false);
  //     })
  //     .catch((err) => console.error(err));
  // };

  const getSingleChat = async () => {
    let token = localStorage.getItem('auth');

    setLoading(true);

    await api
      .userSingleChat(token)
      .then((res) => {
        setMessages(res?.chat);
        setLoading(false);
        // scrollToBottom();
      })
      .catch((err) => console.error(err));
  };

  const handleReloadChat = () => {
    setMessages([]);
  };

  const handleNewChat = () => {
    setMessages([]);
    setNewChat(true);
  };

  return (
    <MathJaxContext>
      <Container isWidget={isWidget} messages={messages}>
        {/* <HistoryWrapper>
          <button
            style={{
              padding: '5px 10px',
              cursor: 'pointer',
            }}
            onClick={() => {}}
            enable={true}
          >
            New Chat
          </button>

          {loading ? (
            <Loader />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  marginTop: '20px',
                }}
              >
                {history?.map((item) => {
                  return (
                    <p
                      style={{
                        cursor: 'pointer',
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: 'Inter',
                        padding: 0,
                        margin: 0,
                      }}
                      onClick={() => {
                        setMessages(item?.chat);
                      }}
                    >
                      {item?.chat[0]?.data}
                    </p>
                  );
                })}
              </div>
            </>
          )}
        </HistoryWrapper> */}
        {/* {!isWidget && (
          <Banner isWidget={isWidget} messages={messages} typing={typing}>
            <BannerContent isWidget={isWidget}>
              <BannerHeading isWidget={isWidget}>
                Octavia - your personal IGCSE tutor!
              </BannerHeading>

              <BannerDetails>
                Welcome to our magical AI tutor for IGCSE / O Level physics,
                chemistry, math and biology.
              </BannerDetails>
            </BannerContent>

            <BannerIconWrapper>
              {!isWidget && messages?.length > 0 && (
                <>
                  <ToolTip
                    title='New Chat'
                    backgroundColor='#251038'
                    arrowColor='#251038'
                  >
                    <BannerIcon
                      src={Add}
                      alt='Add New Chat'
                      onClick={handleNewChat}
                    />
                  </ToolTip>
                </>
              )}
            </BannerIconWrapper>
          </Banner>
        )} */}
        {!isWidget && messages?.length > 0 && (
          <ResetChatWrapper>
            <ToolTip
              title='Clear Screen'
              backgroundColor='#251038'
              arrowColor='#251038'
            >
              <BannerIcon
                src={Restart}
                alt='Reload'
                onClick={handleReloadChat}
              />
            </ToolTip>
          </ResetChatWrapper>
        )}

        {isWidget && (
          <ExpandChatWrapper>
            <BannerIcon
              src={Expand}
              isWidget={isWidget}
              alt='Expand'
              onClick={() => {
                setFullView(!fullView);
              }}
            />

            <BannerIcon
              src={Close}
              isWidget={isWidget}
              alt='Close'
              onClick={HandleBotClick}
            />
          </ExpandChatWrapper>
        )}

        <Wrapper
          ref={wrapperRef}
          isWidget={isWidget}
          messages={messages}
          typing={typing}
        >
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              {isWidget && initialMessage ? (
                <>
                  <AnswerContainer alignItems={'start'}>
                    <img src={TinyOctopus} alt='AI Icon' />

                    <Answer isWidget={isWidget}>
                      <MemoizedMathJax>Hi. How can I help you?</MemoizedMathJax>
                    </Answer>
                  </AnswerContainer>
                </>
              ) : messages?.length > 0 ? (
                <>
                  {messages?.map((item, index) =>
                    item?.type === 'question' ? (
                      <QuestionContainer key={index} messages={messages}>
                        <Question fullView={fullView} isWidget={isWidget}>
                          {item?.data}
                        </Question>

                        <QuestionImageWrapper
                          onMouseEnter={() => setShowName(index)}
                          onMouseLeave={() => setShowName(null)}
                        >
                          <ProfileImg
                            src={user?.img ? user.img : defaultImg}
                            alt='user-dp'
                          />

                          {showName === index && (
                            <UserName>{user.fName}</UserName>
                          )}
                        </QuestionImageWrapper>
                      </QuestionContainer>
                    ) : (
                      <AnswerContainer key={index}>
                        <img src={TinyOctopus} alt='AI Icon' />

                        <Answer isWidget={isWidget}>
                          <MemoizedMathJax key={index}>
                            {renderAnswer(item?.data)}
                          </MemoizedMathJax>
                        </Answer>
                      </AnswerContainer>
                    )
                  )}
                </>
              ) : (
                <>
                  <HeadingWrapper>
                    <Heading>
                      Hello {`${user?.fName} ${user?.lName}`}, how can I help
                      you succeed today?
                    </Heading>
                  </HeadingWrapper>

                  <ContentWrapper>
                    <LogoWrapper>
                      <LogoImg src={Logo} alt='Logo' />
                    </LogoWrapper>
                  </ContentWrapper>
                </>
              )}

              {typing && (
                <TypingWrapper>
                  <img
                    src={TinyOctopus}
                    alt='AI Icon'
                    style={{
                      maxHeight: '48px',
                      maxWidth: '48px',
                    }}
                  />

                  <TypingContainer>
                    {/* <img src={Waves} alt='Typing' /> */}
                    <div class='wave-container'>
                      <div class='bar'></div>
                      <div class='bar'></div>
                      <div class='bar'></div>
                      <div class='bar'></div>
                    </div>

                    <span>Thinking...</span>
                  </TypingContainer>
                </TypingWrapper>
              )}

              <div ref={ref} />

              <InputContainer
                isWidget={isWidget}
                messages={messages}
                fullView={fullView}
              >
                {messages?.length === 0 && (
                  <GeneratedQuestionsWrapper
                    fullView={fullView}
                    isWidget={isWidget}
                  >
                    {(isWidget && !fullView
                      ? generatedQuestions?.slice(0, 3)
                      : generatedQuestions
                    )?.map((item, index) => (
                      <Button
                        key={index}
                        onClick={() => {
                          if (enable) {
                            setGenerateQ(item?.title);
                            setMessages((prev) => [
                              ...prev,
                              { type: 'question', data: item?.title },
                            ]);
                            setInitialMessage(false);
                          }
                        }}
                        enable={enable}
                      >
                        {item?.image}

                        {item?.title}
                      </Button>
                    ))}
                  </GeneratedQuestionsWrapper>
                )}

                <InputWrapper
                  active={question?.length > 0}
                  isWidget={isWidget}
                  fullView={fullView}
                >
                  <img src={ChatIcon} alt='Icon' />

                  <Input
                    type='text'
                    value={question}
                    placeholder={'Ask Octavia a question ...'}
                    onChange={(e) => setQuestion(e.target.value)}
                    ref={textareaRef}
                    rows={1}
                    showScroll={question?.length > 280}
                    isWidget={isWidget}
                    fullView={fullView}
                    active={question?.length > 0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (question?.trim()) {
                          sendQuestion();
                        }
                      } else if (e.key === 'Enter' && e.shiftKey) {
                        e.preventDefault();
                        setQuestion((prev) => prev + '\n');
                      }
                    }}
                  />

                  {streaming ? (
                    <SendWrapper active={true} onClick={handleCancel}>
                      <StopIcon />
                    </SendWrapper>
                  ) : (
                    <SendWrapper
                      active={question?.length > 0}
                      onClick={sendQuestion}
                    >
                      <img src={SendMessage} alt='Send' />
                    </SendWrapper>
                  )}
                </InputWrapper>
              </InputContainer>
            </>
          )}
        </Wrapper>
      </Container>
    </MathJaxContext>
  );
};

export default AiChatBot;
