import React, { useState } from 'react';
import TypographyCompo from './../../../../../UI/Typography/TypographyCompo';
import GridComp from './../../../../../UI/Grid/Grid';
import FlashcardCreate from './../../../../../UI/FlashcardCreate/FlashcardCreate';
import { TextWrapper, DivText } from './MyLibFlashCard.style';
import FlashCardStack from './../../../../../UI/FlashCardStack/FlashCardStack';
import ErrorBoundries from '../../../../../../Errors/ErrorBoundary.js';
import doneImage from '../../../../../../assets/images/flashcard/Saly-8.png';
import inProgress from '../../../../../../assets/images/flashcard/mask_pipe.webp';
import notStart from '../../../../../../assets/images/flashcard/topicTitle.webp';
import {
  DivHeading,
  MobileFlashHeader,
  MobileTypographyName,
  MobileTypographyStats,
  MobileTypographyStatsCompleted,
  MobileTypographyStatsdone,
  TopicTitleMobile,
  IconTextWrapperMobile,
  IconTextTotal,
  IconText,
} from './../../../../../UI/FlashCard/FlashCard.style';
import Divider from '@mui/material/Divider';
import DashboardSvg from './../../../Headers/LearningResources/icons/DashboardSvg';
import StarSvg from './../../../Headers/LearningResources/icons/StarSvg';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import Model from '../../../../../UI/Modal/Modal.jsx';
import FlashcardDeleteModel from './MyLibFlashCardView/FlashcardDeleteModel/FlashcardDeleteModel.jsx';
import capitalize from '../../../../../../helpers/capitalize.js';
import { FlashCardTagsButtonMobile } from '../../../Headers/LearningResources/FlashCard/FlashCard.Style.js';
import MobileDetector from '../../../../../../helpers/mobileDetector.jsx';
import MobileModal from '../../../../../mobileModal/mobileModal.jsx';

const MyLibFlashCard = ({
  clicked,
  MyLibraryFlashCardData,
  loadFlashCardStacks,
  deleteFlashcardStack,
  stackId,
  setStackId,
  isUnit,
  setIsUnit,
  isChapter,
  setIsChpater,
  selectedTag,
}) => {
  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');
  const isMobile = MobileDetector();
  const eachSize = _1000px ? 6 : _750px ? 12 : 12;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      {isMobile ? (
        <MobileModal
          open={openModal}
          close={handleOpenModal}
          modaltext={
            'Are you sure you want to remove this flashcard stack from my library? '
          }
          warningtext={
            'You will be able to find this Stack on Learning Resources'
          }
          succes={() => {
            {
              deleteFlashcardStack(stackId, isUnit, isChapter);
            }
          }}
          isDelete={true}
          reset={handleOpenModal}
        />
      ) : (
        <Model open={openModal} setOpen={handleOpenModal}>
          <FlashcardDeleteModel
            deleteFlashcardStack={() => {
              {
                deleteFlashcardStack(stackId, isUnit, isChapter);
              }
            }}
            setOpen={handleOpenModal}
            setShow={() => {}}
          />
        </Model>
      )}

      <ErrorBoundries>
        <TextWrapper
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 0,
          }}
        >
          <TypographyCompo
            variant='body1'
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            <strong>
              My Saved{' '}
              {MyLibraryFlashCardData.length === 1 ? 'Flashcard' : 'Flashcards'}{' '}
              (
              {MyLibraryFlashCardData.length !== 0
                ? MyLibraryFlashCardData.length !== 1
                  ? `${MyLibraryFlashCardData.length} Stacks`
                  : '1 Stack'
                : '0 Stack'}
              )
            </strong>
          </TypographyCompo>
          <TypographyCompo variant='body1'>
            <FlashcardCreate loadFlashCardStacks={loadFlashCardStacks} />
          </TypographyCompo>
        </TextWrapper>
        <GridComp container spacing={{ xs: 1, sm: 3 }}>
          {MyLibraryFlashCardData.map((item, index) => {
            const specificFlashcards =
              item?.selectedResource === 'unit'
                ? item?.flashcards?.filter((flashcard) => {
                    return flashcard?.chapterId === item?.chapterId;
                  })
                : item?.flashcards?.filter((flashcard) => {
                    return flashcard?.snackId === item?.snackId;
                  });

            const doneFlashCards = specificFlashcards?.filter((specificFC) => {
              return item?.doneFlashcards?.some((doneFC) => {
                if (doneFC?.response === 'YES') {
                  return doneFC?.fcId
                    ? doneFC?.fcId === specificFC?.fcId
                    : doneFC?.userFcId === specificFC?.id;
                }
              });
            });

            return (
              <GridComp
                item
                xs={eachSize}
                key={index}
                sx={{
                  padding: { xs: '0px', sm: '1rem' },
                  position: 'relative',
                }}
              >
                <FlashCardStack
                  onClick={(e) => {
                    clicked(item);
                  }}
                  key={index}
                  paddingTop={10}
                >
                  <MobileFlashHeader sx={{ paddingTop: '10px' }}>
                    <MobileTypographyName>
                      {capitalize(
                        item.selectedResource === 'unit'
                          ? 'chapter'
                          : item.selectedResource === 'chapter'
                          ? 'snack'
                          : 'tag'
                      )}{' '}
                      -{' '}
                      {item.selectedResource === 'unit'
                        ? item.chapterName
                        : item.selectedResource === 'chapter'
                        ? item.snackName
                        : item.tagName}
                    </MobileTypographyName>
                    {doneFlashCards?.length === 0 ? (
                      <MobileTypographyStats style={{ minWidth: 95 }}>
                        {'Not Started Yet'}
                      </MobileTypographyStats>
                    ) : doneFlashCards?.length < specificFlashcards.length ? (
                      <MobileTypographyStatsCompleted>
                        {' '}
                        {'In Progress'}
                      </MobileTypographyStatsCompleted>
                    ) : doneFlashCards?.length === specificFlashcards.length ? (
                      <MobileTypographyStatsdone>
                        {'Completed'}
                      </MobileTypographyStatsdone>
                    ) : (
                      'Score'
                    )}
                  </MobileFlashHeader>
                  <Grid container spacing={0}>
                    <Grid item xs={11}>
                      <DivHeading>
                        <div>
                          <TopicTitleMobile
                            variant='h5'
                            sx={{ marginBottom: '15px' }}
                          >
                            {capitalize(
                              item.selectedResource === 'unit'
                                ? item.chapterName
                                : item.selectedResource === 'chapter'
                                ? item.snackName
                                : item.tagName
                            )}
                          </TopicTitleMobile>
                          <Divider
                            sx={{
                              width: '60%',
                              borderColor: 'rgba(191, 56, 149, 0.13)',
                              marginBottom: '10px',
                            }}
                          />

                          <FlashCardTagsButtonMobile>
                            {capitalize(
                              item.selectedResource !== 'snack'
                                ? capitalize(selectedTag?.title)
                                : capitalize(item?.tagName)
                            )}
                          </FlashCardTagsButtonMobile>

                          <Box sx={{ marginTop: '20px' }}>
                            <Box>
                              <IconTextWrapperMobile>
                                <DashboardSvg isMobile={isMobile} />
                                <Stack direction='row'>
                                  <IconTextTotal>
                                    {specificFlashcards?.length < 9
                                      ? '0' + specificFlashcards?.length
                                      : specificFlashcards?.length}
                                    {'  '}
                                  </IconTextTotal>
                                  <IconText>
                                    {' '}
                                    {specificFlashcards?.length === 1
                                      ? ' Flashcard'
                                      : ' Flashcards'}
                                  </IconText>
                                </Stack>
                              </IconTextWrapperMobile>
                              <IconTextWrapperMobile
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (item?.selectedResource === 'unit') {
                                    setStackId(item?.chapterId);
                                    setIsUnit(true);
                                  } else if (
                                    item?.selectedResource === 'chapter'
                                  ) {
                                    setIsChpater(true);
                                    setStackId(item?.snackId);
                                  } else {
                                    setStackId(item?.tagName);
                                  }
                                  handleOpenModal();
                                }}
                              >
                                <StarSvg show={true} isMobile={isMobile} />
                                <Stack direction='row'>
                                  <IconText show={true}>Saved</IconText>
                                </Stack>
                              </IconTextWrapperMobile>
                            </Box>

                            <Box>
                              {doneFlashCards?.length === 0 ? (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: isMobile ? '20px' : '-40px',
                                    right: '0px',
                                  }}
                                >
                                  <img
                                    src={notStart}
                                    alt='Not Started Yet'
                                    style={{
                                      width: isMobile ? '120px' : '220px',
                                    }}
                                  />
                                </Box>
                              ) : doneFlashCards?.length <
                                specificFlashcards.length ? (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: isMobile ? '30px' : '-40px',
                                    right: '0px',
                                  }}
                                >
                                  <img
                                    src={inProgress}
                                    alt='In Progress'
                                    style={{
                                      width: isMobile ? '120px' : '220px',
                                    }}
                                  />
                                </Box>
                              ) : doneFlashCards?.length ===
                                specificFlashcards.length ? (
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    bottom: '-5px',
                                    right: isMobile ? '-20px' : '-10px',
                                  }}
                                >
                                  <img src={doneImage} alt='completed' />
                                </Box>
                              ) : (
                                ''
                              )}
                            </Box>
                          </Box>
                        </div>
                      </DivHeading>
                    </Grid>
                  </Grid>
                </FlashCardStack>
              </GridComp>
            );
          })}
        </GridComp>
      </ErrorBoundries>
    </div>
  );
};

export default MyLibFlashCard;
