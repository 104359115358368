import React from 'react';

const Help = ({ focused }) => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9375 6.25684V6.31934H20H24C24.5139 6.31934 25.0067 6.52347 25.37 6.88682C25.7334 7.25017 25.9375 7.74298 25.9375 8.25684V24.2568C25.9375 24.5055 25.8387 24.7439 25.6629 24.9198C25.4872 25.0955 25.2488 25.1943 25.0002 25.1943C24.7869 25.1935 24.5802 25.1199 24.4143 24.9857L24.4143 24.9857L19.738 21.2082L19.7208 21.1943H19.6987H8C7.48614 21.1943 6.99333 20.9902 6.62998 20.6269C6.26663 20.2635 6.0625 19.7707 6.0625 19.2568V15.5068V15.3762L5.96077 15.4582L1.58594 18.9855C1.58589 18.9856 1.58584 18.9856 1.58579 18.9857C1.44814 19.0958 1.2822 19.165 1.10704 19.1851C0.931821 19.2052 0.754471 19.1755 0.595367 19.0994C0.436264 19.0233 0.301863 18.9038 0.207604 18.7547C0.113361 18.6057 0.0630684 18.4331 0.0625 18.2567V2.25684C0.0625 1.74298 0.266629 1.25017 0.629981 0.886816C0.993332 0.523465 1.48614 0.319336 2 0.319336H18C18.5139 0.319336 19.0067 0.523465 19.37 0.886816C19.7334 1.25017 19.9375 1.74298 19.9375 2.25684V6.25684ZM5.35805 13.5279L5.35806 13.5279C5.52488 13.393 5.73294 13.3194 5.9475 13.3193H18H18.0625V13.2568V2.25684V2.19434H18H2H1.9375V2.25684V16.1631V16.294L2.0393 16.2117L5.35805 13.5279ZM23.9607 22.2117L24.0625 22.294V22.1631V8.25684V8.19434H24H20H19.9375V8.25684V13.2568C19.9375 13.7707 19.7334 14.2635 19.37 14.6269C19.0067 14.9902 18.5139 15.1943 18 15.1943H8H7.9375V15.2568V19.2568V19.3193H8H20.056C20.2694 19.3202 20.4761 19.3937 20.6419 19.5279L20.6812 19.4793L20.642 19.5279L23.9607 22.2117Z'
        fill={focused ? 'white' : '#ADB4C5'}
        stroke={focused ? 'white' : '#ADB4C5'}
        stroke-width='0.125'
      />
    </svg>
  );
};

export default Help;
