/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import AiChatBot from '../Containers/ChatBot/AiChatBot';
import BotWidget from '../Containers/Home/Sidebar/icons/BotWidget';
import { IFrameIconWrapper, IFrameWrapper } from './iFrams.style';

export default function IframeComp({ showBot, HandleBotClick, url }) {
  const [fullView, setFullView] = useState(false);

  return (
    <div>
      <IFrameWrapper showBot={showBot} fullView={fullView}>
        <AiChatBot
          isWidget={true}
          fullView={fullView}
          setFullView={setFullView}
          HandleBotClick={HandleBotClick}
        />
      </IFrameWrapper>

      <IFrameIconWrapper>
        <div onClick={HandleBotClick}>
          <BotWidget />
        </div>
      </IFrameIconWrapper>
    </div>
  );
}
