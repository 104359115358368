import React from 'react';

const QuestionFive = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5117 4.61719C10.0205 4.61719 9.62085 5.0168 9.62085 5.50805C9.62085 5.99926 10.0205 6.39887 10.5117 6.39887C11.0029 6.39887 11.4025 5.99926 11.4025 5.50805C11.4025 5.01684 11.0029 4.61719 10.5117 4.61719Z"
        fill="url(#paint0_linear_85_5187)"
      />
      <path
        d="M4.86536 5.43055H6.27887L5.57536 3.56641L4.86536 5.43055Z"
        fill="url(#paint1_linear_85_5187)"
      />
      <path
        d="M14.25 0H1.75C0.780742 0 -0.0078125 0.788555 -0.0078125 1.75781V9.45312H16.0078V1.75781C16.0078 0.788555 15.2193 0 14.25 0ZM7.59184 7.52715C7.33953 7.62238 7.05781 7.495 6.96258 7.24273L6.64742 6.40762H4.49344L4.17484 7.24414C4.07887 7.49613 3.79676 7.62266 3.54477 7.52664C3.29273 7.43066 3.16625 7.14855 3.26223 6.89656L5.02238 2.27508C5.02379 2.27145 5.0252 2.26777 5.02668 2.26414C5.11836 2.03992 5.33406 1.89484 5.57633 1.89457H5.57703C5.81902 1.89457 6.0348 2.0391 6.12684 2.26293C6.12863 2.26734 6.13039 2.2718 6.13211 2.27625L7.87625 6.89793C7.97148 7.1502 7.84414 7.43191 7.59184 7.52715ZM12.3791 7.07031C12.3791 7.33996 12.1604 7.55859 11.8908 7.55859C11.6466 7.55859 11.4448 7.37914 11.4088 7.14504C11.1423 7.2916 10.8366 7.37523 10.5116 7.37523C9.48199 7.37523 8.64426 6.53754 8.64426 5.50785C8.64426 4.47816 9.48195 3.64043 10.5116 3.64043C10.8366 3.64043 11.1423 3.72402 11.4088 3.87063C11.4448 3.63652 11.6466 3.45707 11.8908 3.45707C12.1604 3.45707 12.3791 3.6757 12.3791 3.94535V7.07031Z"
        fill="url(#paint2_linear_85_5187)"
      />
      <path
        d="M-0.0078125 10.625V18.2422C-0.0078125 19.2114 0.780742 20 1.75 20H14.25C15.2193 20 16.0078 19.2114 16.0078 18.2422V10.625H-0.0078125ZM5.65625 12.3828H10.3438C10.6673 12.3828 10.9297 12.6452 10.9297 12.9688C10.9297 13.2923 10.6673 13.5547 10.3438 13.5547H5.65625C5.33266 13.5547 5.07031 13.2923 5.07031 12.9688C5.07031 12.6452 5.33266 12.3828 5.65625 12.3828ZM10.7344 18.2422H2.92188C2.59828 18.2422 2.33594 17.9798 2.33594 17.6562C2.33594 17.3327 2.59828 17.0703 2.92188 17.0703H10.7344C11.058 17.0703 11.3203 17.3327 11.3203 17.6562C11.3203 17.9798 11.058 18.2422 10.7344 18.2422ZM13.0781 15.8984H2.92188C2.59828 15.8984 2.33594 15.6361 2.33594 15.3125C2.33594 14.9889 2.59828 14.7266 2.92188 14.7266H13.0781C13.4017 14.7266 13.6641 14.9889 13.6641 15.3125C13.6641 15.6361 13.4017 15.8984 13.0781 15.8984Z"
        fill="url(#paint3_linear_85_5187)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_85_5187"
          x1="10.5117"
          y1="4.61719"
          x2="10.5117"
          y2="6.39887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_85_5187"
          x1="5.57211"
          y1="3.56641"
          x2="5.57211"
          y2="5.43055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_85_5187"
          x1="8"
          y1="0"
          x2="8"
          y2="9.45312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_85_5187"
          x1="8"
          y1="10.625"
          x2="8"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuestionFive;
