import React from 'react';
import {
  Wrapper,
  DashboardIconWrapper,
  Image,
  SideBarMenutitle,
} from './Sidebar.style';
import Stack from '../../../UI/Stack/Stack';
import logo from '../../../../assets/images/logo/Main_logo.svg';
import IconButton from './../../../UI/IconButton/IconButton';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import BotSvg from './icons/BotSvg';
import Dashboard from '../../../../assets/images/sidebar/Dashboard';
import Help from '../../../../assets/images/sidebar/Help';
import { Mixpanel } from '../../../../helpers/Mixpanel';

const Sidebar = ({ drawerwidth, setDrawerWidth, isXs }) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState({
    dashboard: false,
    chatbot: false,
    message: false,
    settings: false,
    doubleArrows: false,
    help: false,
  });

  const enterHoverHandler = (e) => {
    let temp = { ...hovered };
    temp[e.currentTarget.getAttribute('id')] = true;
    setHovered(temp);
  };

  const leaveHoverHandler = (e) => {
    let temp = { ...hovered };
    temp[e.currentTarget.getAttribute('id')] = false;
    setHovered(temp);
  };
  return (
    <Wrapper drawerwidth={drawerwidth}>
      {isXs && drawerwidth === '75px' ? (
        <Box>
          <Stack
            alignItems='center'
            justifyContent='center'
            direction='row'
            sx={{ position: 'absolute', top: '20px', left: '25px' }}
          >
            <IconButton onClick={() => setDrawerWidth('0px')}>
              <ClearIcon style={{ color: '#675874' }} />
            </IconButton>
          </Stack>
        </Box>
      ) : null}
      <Stack
        space={0}
        alignItems='center'
        sx={{ pt: isXs && drawerwidth === '75px' ? '65px' : '18px' }}
      >
        <Link to='/dashboard'>
          <Image
            src={logo}
            alt='logo'
            style={{
              marginLeft: drawerwidth === '0px' ? '0px' : '0px',
              display: drawerwidth === '0px' ? 'none' : 'block',
              width: '58px',
              height: '48px',
              marginBottom: '70px',
              marginTop: '41px',
            }}
          />
        </Link>

        <DashboardIconWrapper
          onMouseOut={leaveHoverHandler}
          onMouseOver={enterHoverHandler}
          id='dashboard'
          isMobile={isXs && drawerwidth === '75px'}
          onClick={(e) => {
            navigate('/dashboard');
          }}
        >
          <SideBarMenutitle hovered={hovered.dashboard}>
            Dashboard
          </SideBarMenutitle>

          <IconButton width='44px'>
            <Dashboard focused={hovered.dashboard} />
          </IconButton>
        </DashboardIconWrapper>

        <DashboardIconWrapper
          onMouseOut={leaveHoverHandler}
          onMouseOver={enterHoverHandler}
          id='chatbot'
          isMobile={isXs && drawerwidth === '75px'}
          onClick={(e) => {
            navigate('/chatbot');
          }}
        >
          <SideBarMenutitle hovered={hovered.chatbot}>
            AI Tutor
          </SideBarMenutitle>

          <IconButton height='35px' width='33px'>
            <BotSvg focused={hovered.chatbot} isSidebar={true} />
          </IconButton>
        </DashboardIconWrapper>

        <DashboardIconWrapper
          onMouseOut={leaveHoverHandler}
          onMouseOver={enterHoverHandler}
          id='help'
          isMobile={isXs && drawerwidth === '75px'}
          onClick={(e) => {
            navigate('/help');

            Mixpanel.track('FAQ Page Clicked', {
              page: 'FAQ',
            });
          }}
          className='sidebar-help'
        >
          <SideBarMenutitle hovered={hovered.help}>Help</SideBarMenutitle>

          <IconButton height='32px' width='22px'>
            <Help focused={hovered.help} />
          </IconButton>
        </DashboardIconWrapper>
      </Stack>

      {/* <SideBarOutterWrapper>
        <SideBarBottomWrapper>
          <IconButton onClick={() => navigate('/user')}>
            <SettingSvg />
          </IconButton>
        </SideBarBottomWrapper>
      </SideBarOutterWrapper> */}
    </Wrapper>
  );
};

export default Sidebar;
