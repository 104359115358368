import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SnackAnalytics from './SnackAnalytics';
import ChapterAnalytics from './ChapterAnalytics';
import Alert from '../../../../../../../UI/Alert/Alert';
import capitalize from '../../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../../assets/images/assessment';
import {
  UserAnalyticsContent,
  UserAnalyticsHeader,
  UserAnalyticsHeading,
  UserAnalyticsResetBtn,
  UserAnalyticsWrapper,
  AnalyticsHeaderWrapper,
  AnalyticsNewUIWrapper,
  AnalyticsContent,
  AnlayticsResetBtn,
  WelcomeHeading,
  WelcomeImage,
  UnorderList,
  ResetBtnWrapper,
  UserAnalyticsShowBtn,
} from '../Analytics.style';
import MobileCourseIndicator from '../../../../../../../mobileBottomBar/MobileCourseIndicator';
import { useMediaQuery } from '@mui/material';
import Modal from '../../../../../../../UI/Modal/Modal';
import QuestionCard from '../../Assessment/components/QuestionCard';
import {
  PercentageSpan,
  ResultCorrectScores,
  ResultProgrssWrapper,
  ResultScoresContent,
  ResultScoresContentWrapper,
  ResultScoresHeading,
  ReviewCardWrapper,
} from '../../Assessment/Assessment.style';
import {
  GIF,
  GIFWrapper,
  GotItButton,
  IncorrectTitle,
  ModalHeading,
  ModalWrapper,
} from './chapterAnalyticsStyle';
import Congratulation from '../../../../../../../../assets/images/user/Congratulation.gif';
import Celebration from '../../../../../../../../assets/images/user/Celebration.gif';
import ResultScreen from '../../Assessment/screens/ResultScreen';

const AnalyticsScreen = ({
  names,
  activeLevel,
  attemptedAnswer,
  analyticsData,
  newSelection,
  setDeleteModal,
  chapter,
  getUserIncorrectData,
  setIsCorrect,
  showQuestions,
  setShowQuestions,
  incorrectQuestions,
  isCorrect,
  showModal,
  setShowModal,
  allQuestions,
}) => {
  const [showSnacks, setShowSnacks] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const _750px = useMediaQuery('(max-width: 750px)');

  const correctStatus = isCorrect === 'Incorrect' ? false : true;

  let chapterData;

  if (activeLevel === 'chapter') {
    chapterData = analyticsData?.reduce((acc, obj) => {
      Object.keys(obj)?.forEach((key) => {
        acc[key] = (acc[key] || 0) + obj[key];
      });

      return acc;
    }, {});
  }

  useEffect(() => {
    if (activeLevel === 'chapter') {
      setIsEmpty(chapterData?.attemptedQuestions > 0);
    } else {
      setIsEmpty(analyticsData?.attemptedQuestions > 0);
    }
  }, [analyticsData]);

  console.log('analyticsData: ', analyticsData);
  console.log('chapterData: ', chapterData);
  return (
    <>
      {activeLevel === 'unit' ? (
        <>
          <MobileCourseIndicator
            tab={'Analytics'}
            welcomeDetails='Please select a chapter or snack to view Analytics'
          />
          <AnalyticsHeaderWrapper>
            <AnalyticsNewUIWrapper _750px={_750px}>
              <WelcomeImage src={GRAPHICS.Octopus} alt='img' />

              <AnalyticsContent>
                <WelcomeHeading>
                  Select a topic or snack on the left to view your analytics.
                </WelcomeHeading>

                <UnorderList>
                  <li>
                    To see analytics on a topic, click on the topic title.
                  </li>

                  <li>
                    To see analytics on a snack (sub-topic), expand the topic
                    and click on the snack.
                  </li>
                </UnorderList>
              </AnalyticsContent>
            </AnalyticsNewUIWrapper>

            <ResetBtnWrapper>
              <AnlayticsResetBtn onClick={() => setDeleteModal(true)}>
                Reset Analytics
              </AnlayticsResetBtn>
            </ResetBtnWrapper>
          </AnalyticsHeaderWrapper>
        </>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            {/* Header */}

            <UserAnalyticsWrapper>
              {showQuestions && (
                <UserAnalyticsShowBtn onClick={() => setShowQuestions(false)}>
                  Show Analytics
                </UserAnalyticsShowBtn>
              )}

              <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <img src={GRAPHICS.Header} alt='' />
              </Box>

              <UserAnalyticsHeader>
                <UserAnalyticsHeading>Analytics</UserAnalyticsHeading>

                <UserAnalyticsContent>
                  Topic: {capitalize(chapter)}{' '}
                  {activeLevel === 'snack' && (
                    <span>- Snack: {newSelection?.label}</span>
                  )}
                </UserAnalyticsContent>
              </UserAnalyticsHeader>

              <UserAnalyticsResetBtn onClick={() => setDeleteModal(true)}>
                Reset Analytics
              </UserAnalyticsResetBtn>
            </UserAnalyticsWrapper>

            {showQuestions ? (
              <>
                {incorrectQuestions?.length > 0 && (
                  <ResultScreen
                    analyticsQuestions={incorrectQuestions}
                    isAnalytics={true}
                    correctStatus={correctStatus}
                    setIsCorrect={setIsCorrect}
                    analyticsAllQuestions={allQuestions}
                  />
                )}
              </>
            ) : activeLevel === 'chapter' ? (
              <ChapterAnalytics
                names={names}
                attemptedAnswer={attemptedAnswer}
                analyticsData={analyticsData}
                newSelection={newSelection}
                showSnacks={showSnacks}
                setShowSnacks={setShowSnacks}
                chapterData={chapterData}
                getUserIncorrectData={getUserIncorrectData}
                setIsCorrect={setIsCorrect}
              />
            ) : activeLevel === 'snack' ? (
              <SnackAnalytics
                names={names}
                analyticsData={analyticsData}
                newSelection={newSelection}
                getUserIncorrectData={getUserIncorrectData}
                setIsCorrect={setIsCorrect}
              />
            ) : (
              ''
            )}

            {showModal && (
              <Modal
                open={showModal}
                setOpen={setShowModal}
                modalStyleOverrides={{ background: 'white' }}
              >
                <ModalWrapper>
                  {isEmpty && (
                    <>
                      <GIFWrapper>
                        <GIF src={Celebration} alt='Not Found' />
                        <GIF src={Congratulation} alt='Not Found' />
                        <GIF src={Celebration} alt='Not Found' />
                      </GIFWrapper>

                      <ModalHeading>Well done!</ModalHeading>
                    </>
                  )}

                  <IncorrectTitle>
                    {isEmpty ? (
                      `There are no ${isCorrect?.toLowerCase()} responses in ${
                        newSelection?.label
                      }.`
                    ) : (
                      <>
                        You have not attempted any questions on{' '}
                        <strong>{newSelection?.label}</strong>.
                      </>
                    )}
                  </IncorrectTitle>

                  <GotItButton onClick={() => setShowModal(false)}>
                    Got it!
                  </GotItButton>
                </ModalWrapper>
              </Modal>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default AnalyticsScreen;
