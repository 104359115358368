import { styled } from '@mui/system';
import Stack from '../../../UI/Stack/Stack';
import Typography from '../../../UI/Typography/TypographyCompo';
import { ListItemButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const NavContent = styled(Stack)(() => ({
  height: '100%',
  // opacity: 0.8,
  opacity: 1,
  transition: 'all 0.3s',
  // '&:hover': {
  //   opacity: 1,
  // },
  width: '100%',
}));

export const Img = styled('img')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  objectFit: 'cover',
}));

export const ProfileWrapper = styled('div')(() => ({
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  marginRight: '32px !important',
  borderRadius: '16px',
  padding: '8px',
  cursor: 'pointer',
  position: 'relative',

  '&:hover': {
    background: 'white',
  },
}));

export const ImgContainer = styled('div')(() => ({
  borderRadius: '16px',
  width: '32px',
  height: '32px',
}));

export const UserName = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#251038',
  textTransform: 'capitalize',
}));
export const UserCategory = styled(Typography)(({ theme }) => ({
  color: '#624BA2',
  fontStyle: 'italic',
  fontSize: '14px',
  marginTop: 0,
  fontWeight: 400,
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledNav = styled('div')(
  ({ theme, appbarheight, isQuestion }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    // background: 'rgba(255, 255, 255, 0.80)',
    background: '#FAFAFA',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)',
    backdropFilter: 'blur(6.5px)',
    height: appbarheight,
    zIndex: isQuestion ? 1600 : 900,
  })
);

export const NotBtn = styled('div')(({ theme }) => ({
  width: '100%',
  height: '28px',
  color: '#fff',
  borderRadius: '14px',
  background: theme.palette.secondary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  gap: '3px',
  boxShadow: '0px 5px 34px rgba(186, 19, 88, 0.42);',
  cursor: 'pointer',
}));

export const ListItemButtonWrapper = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.spacing(1.75),
  width: '48px',
  height: theme.spacing(3.5),
  display: 'inline-block',
  padding: 0,
}));
export const Span = styled('span')(({ theme }) => ({
  cursor: 'pointer',
}));
export const StackWrapper = styled(Stack)(({ theme }) => ({
  width: '225px',
  marginRight: '50px',
  [theme.breakpoints.down('sm')]: {
    marginRight: '0px',
  },
}));

export const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  width: '35px',
  height: '35px',
}));

// Banner

export const Banner = styled('div')(({ theme }) => ({
  background: '#452969',
  zIndex: 1700,
  position: 'absolute',
  top: 0,
  right: '25%',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  width: '50%',
  minHeight: '34px',
  padding: '0px 10px',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '0px 0px 10px 10px',

  '&:hover': {
    background: 'rgb(95, 57, 145)',
  },

  [theme.breakpoints.down('sm')]: {
    width: '80%',
    right: '10%',
  },
}));

export const BannerContent = styled('span')(() => ({
  fontSize: '14px',
  fontFamily: 'Satoshi',
  fontWeight: 700,
}));

export const BannerLink = styled('a')(() => ({
  fontSize: '15px',
  color: 'white',
}));

export const BannerButton = styled('button')(() => ({
  position: 'absolute',
  right: '30px',
  background: 'transparent',
  color: 'white',
  border: 'transparent',
  textAlign: 'center',
  cursor: 'pointer',
}));

export const ProfileModal = styled('div')(() => ({
  width: '168px',
  background: 'white',
  position: 'absolute',
  top: '55px',
  right: '0px',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  paddingBottom: '8px',
  opacity: 1,
  zIndex: 9999,
}));

export const ProfileModalSection = styled('div')(({ logout, border }) => ({
  padding: '10px 20px',
  background: logout && '#FFF5F5',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  borderBottom: border && '1px solid #ECECED',
  cursor: 'pointer',
  justifyContent: 'start',
  // borderRadius: logout && "0px 0px 16px 16px"

  '&:hover': {
    background: '#F6F6F6',
  },
}));

export const ProfileModalTitle = styled('span')(({ logout }) => ({
  color: logout ? '#F00000' : '#494656',
  fontSize: '14px',
  fontFamily: 'Inter',
  fontWeight: 600,
}));
